import React, { useEffect, useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { IoMdRefresh } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loading from "../Components/Loading";
import CryptoJS from "crypto-js";
import Error from "../Components/Error";
function ApiConfiguration() {
  const base_url = process.env.REACT_APP_BASE_URL;
  const key = localStorage.getItem("key");
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    ipAddress: "",
    callbackUrl: "",
    apiToken: "Generated_API_Token",
  });
  const [loadingButton, setLoadingButton] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState([]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const handleBack = () => {
    navigate(-1);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleSubmit = async () => {
    if (!formData.ipAddress || !formData.callbackUrl) {
      toast.error("Please fill out all fields.");
      return;
    }
    setLoadingButton(true);
    try {
      const response = await fetch(`${base_url}/api/configure`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(formData),
      });
      const data = await response.json();
      if (response.ok) {
        toast.success("Configuration submitted successfully!");
      } else {
        toast.error(data.message || "Failed to submit configuration.");
      }
    } catch (error) {
      setError(true);
    } finally {
      setLoadingButton(false);
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${base_url}app/api-info.php?key=${key}`);

        if (!response.ok) {
          throw Error("Error");
        }
        const data = await response.json();
        if (data.status === "Success") {
          setData(data);
          setFormData((prevFormData) => ({
            ...prevFormData,
            apiToken: data.data1,
          }));
        } else {
          throw Error(data.message);
        }
      } catch (error) {
        setError(true);
        toast.error(error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const regenerateToken = () => {
    const randomString = Math.random().toString(36).substring(2) + Date.now().toString(36);
    const hashedToken = CryptoJS.MD5(randomString).toString();
    setFormData({ ...formData, apiToken: hashedToken });
    toast.info("API Token regenerated.");
  };
  return (
    <>
      {loading ? (
        <Loading />
      ) : error ? (
        <Error title={"Request failed"} description={"You are not authorized to access"} />
      ) : (
        <div className="bg-slate-200 min-h-screen">
          <div className="h-[60px] w-full appBgColor flex items-center px-3 gap-3 text-white sticky top-0">
            <button onClick={handleBack}>
              <FaArrowLeft size={20} />
            </button>
            <h1 className="font-bold">API Configuration</h1>
          </div>
          <form>
            <div className="px-3 mt-4">
              <div>
                <label htmlFor="ipAddress" className="text-[16px] font-semibold">
                  IP Address
                </label>
                <input type="text" name="ipAddress" value={data.data2} onChange={handleChange} className="border-2 appBorderColor p-2 py-[12px] mb-3 w-full mt-1 outline-none rounded-md" placeholder="Enter IP address" required />
              </div>
              <div>
                <label htmlFor="callbackUrl" className="text-[16px] font-semibold">
                  Callback Url
                </label>
                <input type="text" name="callbackUrl" value={data.data3} onChange={handleChange} className="border-2 appBorderColor p-2 py-[12px] mb-3 w-full mt-1 outline-none rounded-md" placeholder="Enter callback URL" required />
              </div>
              <div>
                <label htmlFor="apiToken" className="text-[16px] font-semibold">
                  API Token
                </label>
                <div className="relative">
                  <input type="text" name="apiToken" value={formData.apiToken} readOnly className="border-2 appBorderColor p-2 py-[12px] mb-3 w-full mt-1 outline-none rounded-md" />
                  <IoMdRefresh size={25} className="absolute top-4 right-3 cursor-pointer" onClick={regenerateToken} />
                </div>
              </div>
              <div className="mt-6">
                <button type="button" onClick={handleSubmit} className="appBgColor text-white w-full py-[12px] rounded-lg select-none" disabled={loadingButton}>
                  {loadingButton ? (
                    <div className="flex items-center justify-center">
                      <div className="w-4 h-4 border-2 border-white border-t-transparent rounded-full animate-spin"></div>
                      <span className="ml-2">Processing...</span>
                    </div>
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            </div>
          </form>
          <ToastContainer />
        </div>
      )}
    </>
  );
}
export default ApiConfiguration;
