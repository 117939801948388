import React, { useState, useEffect } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Loading from "../Components/Loading";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Error from "../Components/Error";
function OperatorForm() {
  const base_url = process.env.REACT_APP_BASE_URL;
  const key = localStorage.getItem("key");

  const [searchParams] = useSearchParams();
  const operator_id = searchParams.get("operator_id");

  const [formData, setFormData] = useState({});
  const [fields, setFields] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingButton, setLoadingButton] = useState(false);
  const [error, setError] = useState("");
  const [billInfo, setBillInfo] = useState(null);
  const [operatorIcon, setOperatorIcon] = useState("");
  const navigate = useNavigate();

  const handleBack = () => navigate(-1);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchOperatorData = async () => {
      try {
        const response = await fetch(`${base_url}app/operator_input_field.php?key=${key}&operator_id=${operator_id}`);
        if (!response.ok) {
          throw new Error("Failed to fetch user data");
        }
        const data = await response.json();
        if (data.status === "Success") {
          const { nparam, ...rest } = data.data1;

          const dynamicFields = [];
          for (let i = 1; i <= parseInt(nparam, 10); i++) {
            dynamicFields.push({
              name: `param${i}`,
              label: rest[`paramName_${i}`],
              placeholder: rest[`paramHint_${i}`],
              type: rest[`paramType_${i}`] === "1" ? "number" : rest[`paramType_${i}`] === "2" ? "text" : "select",
              required: rest[`paramIsOptional_${i}`] === "0",
              regex: rest[`paramRegEx_${i}`] || null,
              minLength: rest[`paramMinL_${i}`] || 0,
              maxLength: rest[`paramMaxL_${i}`] || 0,
            });
          }

          setFields(dynamicFields);
          setBillInfo(data.data1);
          setOperatorIcon(data.data1.operator_logo);
        } else {
          setError(data.message);
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (operator_id) fetchOperatorData();
  }, [base_url, key, operator_id]);

  const handleInputChange = (e, field) => {
    setFormData({ ...formData, [field.name]: e.target.value });
  };

  const validateField = (value, field) => {
    if (field.required && !value) return `${field.label} is required`;
    if (field.regex && !new RegExp(field.regex).test(value)) return `${field.label} does not match the required format`;
    if (field.minLength > 0 && value.length < field.minLength) return `${field.label} must be at least ${field.minLength} characters`;
    if (field.maxLength > 0 && value.length > field.maxLength) return `${field.label} must be at most ${field.maxLength} characters`;
    return null;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoadingButton(true);

    const errors = fields.map((field) => validateField(formData[field.name], field));
    const hasErrors = errors.some((error) => error !== null);

    if (hasErrors) {
      setError(errors.find((error) => error !== null));
      toast.error("Please correct the highlighted errors before submitting.");
      setLoadingButton(false);
      return;
    }

    try {
      navigate("/billFetch", { state: { formData, operator_id } });
    } catch (error) {
      setError("Something went wrong. Please try again.");
      toast.error("Submission failed. Please try again.");
    } finally {
      setLoadingButton(false);
    }
  };

  if (loading) return <Loading />;

  return (
    <>
      {loading ? (
        <Loading />
      ) : error ? (
        <Error title={"Something went wrong"} description={"Api input form not found"} />
      ) : (
        <div>
          <div className="text-white bg-slate-200 h-screen">
            <div className="appBgColor p-2 py-4 pb-9 ">
              <header className="flex items-center gap-4">
                <button onClick={handleBack}>
                  <FaArrowLeft size={20} />
                </button>
                <button className="text-[20px] font-semibold">Form Fill</button>
              </header>

              <div className="mt-4 flex items-center justify-between">
                <div className="space-y-1">
                  <h2 className="text-xl font-bold">{billInfo?.biller_name}</h2>
                  <p className="text-sm">Once a payment is successfully processed and added to your account, it cannot be reversed.</p>
                </div>
                <img src={billInfo.operator_logo} alt="icon" className="h-[50px] rounded-full bg-white" />
              </div>
            </div>

            <form onSubmit={handleSubmit} className="px-2 py-5 bg-slate-200">
              {fields.map((field, index) => (
                <div key={index} className="mb-4">
                  <input type={field.type} name={field.name} id={field.name} placeholder={field.placeholder} required={field.required} className="w-full px-4 py-3 text-black bg-white rounded-lg border-2 appBorderColor outline-none" onChange={(e) => handleInputChange(e, field)} />
                </div>
              ))}
              <button type="submit" className="appBgColor text-white w-full py-[12px] rounded-lg select-none" disabled={loadingButton}>
                {loadingButton ? (
                  <div className="flex items-center justify-center">
                    <div className="w-4 h-4 border-2 border-white border-t-transparent rounded-full animate-spin"></div>
                  </div>
                ) : (
                  <>Continue</>
                )}
              </button>
            </form>
            <ToastContainer />
          </div>
        </div>
      )}
    </>
  );
}

export default OperatorForm;
