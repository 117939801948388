import React, { useEffect, useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loading from "../Components/Loading";
import Error from "../Components/Error";

const DayBook = () => {
  const base_url = process.env.REACT_APP_BASE_URL;
  const key = localStorage.getItem("key");
  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const [fromDate, setFromDate] = useState(getCurrentDate());
  const [toDate, setToDate] = useState(getCurrentDate());
  const [data1, setData1] = useState({});
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${base_url}app/day-book.php?key=${key}&from_date=${fromDate}&to_date=${toDate}`);

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      if (result.status !== true) {
        toast.error("Data fetching error");
      }

      setData1(result.data1 || {});
      setData(result.data2 || []);
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleSubmit();
  }, []);

  const navigate = useNavigate();
  const handleBack = () => {
    navigate(-1);
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : error ? (
        <Error title={"Something went wrong"} description={"API fetching error"} />
      ) : (
        <div className="bg-slate-200 min-h-screen">
          <div className="h-[60px] w-full appBgColor flex items-center px-3 gap-3 text-white sticky top-0">
            <button onClick={handleBack}>
              <FaArrowLeft size={20} />
            </button>
            <h1 className="font-bold text-[17px]">Day Book</h1>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 px-3 md:px-4 gap-2 md:gap-4 pt-5">
            <div>
              <label className="block text-sm font-medium text-gray-700">From Date</label>
              <input type="date" value={fromDate} onChange={(e) => setFromDate(e.target.value)} className="w-full py-3 border-2 appBorderColor rounded-lg bg-white outline-none px-2" />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">To Date</label>
              <input type="date" value={toDate} onChange={(e) => setToDate(e.target.value)} className="w-full py-3 border-2 appBorderColor rounded-lg bg-white outline-none px-2" />
            </div>
            <div className="w-full">
              <button onClick={handleSubmit} className="appBgColor border-2 appBorderColor text-white py-[12px] rounded-lg mt-2 select-none w-full md:translate-y-[12px]">
                Search
              </button>
            </div>
          </div>

          <div className="grid grid-cols-2 md:grid-cols-4 gap-2 md:gap-4 w-full px-3 md:px-4 mt-5 select-none">
            {[
              { label: "Current Balance", value: data1.current_balance || "0.00" },
              { label: "Add Money", value: data1.TotalAdd || "0.00" },
              { label: "Total Recharge", value: data1.TotalRecharge || "0.00" },
              { label: "Total Billpay", value: data1.TotalBill || "0.00" },
              { label: "Total Earn", value: data1.TotalProfit || "0.00" },
              { label: "Extra Bonus", value: data1.TotalBonus || "0.00" },
              { label: "Debit Money", value: data1.TotalDebit || "0.00" },
              { label: "Difference", value: data1.difference || "0.00" },
            ].map((item, index) => (
              <div key={index} className="bg-white rounded-md flex flex-col items-center justify-center gap- px-2 h-[100px] lg:h-[110px] py-5">
                <h1 className="text-[20px] font-bold appTextColor">{item.value}</h1>
                <h1>{item.label}</h1>
              </div>
            ))}
          </div>
          <div className="overflow-x-auto my-5 mx-3 pb-5 select-none">
            <table className="min-w-full table-auto border-collapse border border-gray-300">
              <thead>
                <tr className="bg-green-600 text-white">
                  <th className="px-4 py-2 text-left text-sm font-semibold border border-gray-300">Operator</th>
                  <th className="px-4 py-2 text-left text-sm font-semibold border border-gray-300">Success</th>
                  <th className="px-4 py-2 text-left text-sm font-semibold border border-gray-300">Failed</th>
                  <th className="px-4 py-2 text-left text-sm font-semibold border border-gray-300">Pending</th>
                  <th className="px-4 py-2 text-left text-sm font-semibold border border-gray-300">Profit</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item) => (
                  <tr key={item.id} className="bg-white">
                    <td className="px-4 py-2 text-sm text-gray-700 border border-gray-300">{item.biller_name}</td>
                    <td className="px-4 py-2 text-sm text-gray-700 border border-gray-300">{item.SuccessAmount}</td>
                    <td className="px-4 py-2 text-sm text-gray-700 border border-gray-300">{item.FailedAmount}</td>
                    <td className="px-4 py-2 text-sm text-gray-700 border border-gray-300">{item.PendingAmount}</td>
                    <td className="px-4 py-2 text-sm text-gray-700 border border-gray-300">{item.profits}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <ToastContainer />
        </div>
      )}
    </>
  );
};

export default DayBook;
