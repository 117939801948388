import React, { useEffect } from "react";
import "../index.css";
import { FaArrowLeft } from "react-icons/fa";
import { FiCreditCard } from "react-icons/fi";
import { TbDeviceLandlinePhone } from "react-icons/tb";
import { Link, useNavigate } from "react-router-dom";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import LiveTvIcon from "@mui/icons-material/LiveTv";
import ElectricMeterOutlinedIcon from "@mui/icons-material/ElectricMeterOutlined";
import ConnectedTvOutlinedIcon from "@mui/icons-material/ConnectedTvOutlined";
import DirectionsCarFilledOutlinedIcon from "@mui/icons-material/DirectionsCarFilledOutlined";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import LocalGasStationOutlinedIcon from "@mui/icons-material/LocalGasStationOutlined";
import WaterDropOutlinedIcon from "@mui/icons-material/WaterDropOutlined";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import RouterOutlinedIcon from "@mui/icons-material/RouterOutlined";
import GasMeterOutlinedIcon from "@mui/icons-material/GasMeterOutlined";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import LocalHospitalOutlinedIcon from "@mui/icons-material/LocalHospitalOutlined";
import HealthAndSafetyOutlinedIcon from "@mui/icons-material/HealthAndSafetyOutlined";
import BiotechOutlinedIcon from "@mui/icons-material/BiotechOutlined";
import Diversity2OutlinedIcon from "@mui/icons-material/Diversity2Outlined";
import Diversity1OutlinedIcon from "@mui/icons-material/Diversity1Outlined";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import DomainOutlinedIcon from "@mui/icons-material/DomainOutlined";
import LibraryBooksOutlinedIcon from "@mui/icons-material/LibraryBooksOutlined";
import PaymentsOutlinedIcon from "@mui/icons-material/PaymentsOutlined";
import ShieldOutlinedIcon from "@mui/icons-material/ShieldOutlined";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import SubscriptionsOutlinedIcon from "@mui/icons-material/SubscriptionsOutlined";
const SeeMore = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleBack = () => {
    navigate(-1);
  };
  return (
    <div className="bg-slate-200 min-h-screen">
      <div className="h-[60px] appBgColor text-white flex items-center px-3 sticky top-0">
        <button onClick={handleBack}>
          <FaArrowLeft size={20} />
        </button>
        <h1 className="text-lg font-semibold mx-3">Bill Payments</h1>
      </div>
      {/* Categories */}
      <div className="p-2 space-y-6 pb-4 px-3">
        <div>
          <h2 className="appTextColor font-bold mb-2 text-[16px]">Mobile Recharge & Others</h2>
          <div className=" w-full grid grid-cols-4 lg:grid-cols-8 gap-1.5 select-none rounded">
            <Link to="/Prepaid" className="bg-white gap-1 p-1 h-[72px] lg:h-[120px] flex flex-col items-center justify-center rounded px-2">
              <PhoneAndroidIcon style={{ fontSize: "25px", color: "gray" }} />
              <h1 className="text-[11px] text-gray-600 font-bold leading-[1.1] text-center">Mobile Recharge</h1>
            </Link>
            <Link to="/operator?category=Dth&sub_category=Non BBPS" className="bg-white gap-1 p-1 h-[72px] lg:h-[120px] flex flex-col items-center justify-center rounded px-3">
              <LiveTvIcon style={{ fontSize: "25px", color: "gray" }} />
              <h1 className="text-[11px] text-gray-600 font-bold leading-[1.1] text-center">DTH Recharge</h1>
            </Link>
            {/* <Link to="/comingsoon" className="bg-white gap-1 p-1 h-[72px] lg:h-[120px] flex flex-col items-center justify-center rounded px-3">
              <ElectricMeterOutlinedIcon style={{ fontSize: "25px", color: "gray" }} />
              <h1 className="text-[11px] text-gray-600 font-bold leading-[1.1] text-center">Prepaid Meter</h1>
            </Link> */}
            <Link to="/operator?category=Cable TV&sub_category=BBPS" className="bg-white gap-2 p-1 h-[72px] lg:h-[120px] flex flex-col items-center justify-center rounded px-2">
              <ConnectedTvOutlinedIcon style={{ fontSize: "25px", color: "gray" }} />
              <h1 className="text-[11px] text-gray-600 font-bold">Cable TV</h1>
            </Link>
            <Link to="/operator?category=Fastag&sub_category=BBPS" className="bg-white gap-2 p-1 h-[72px] lg:h-[120px] flex flex-col items-center justify-center rounded px-2">
              <DirectionsCarFilledOutlinedIcon style={{ fontSize: "25px", color: "gray" }} />
              <h1 className="text-[11px] text-gray-600 font-bold leading-[1.1] text-center">Fastag</h1>
            </Link>
            {/* <Link to="/comingsoon" className="bg-white gap-2 p-1 h-[72px] lg:h-[120px] flex flex-col items-center justify-center rounded px-2">
              <img src="/categories/ncmc.png" className="h-[28px] grayscale opacity-65" alt="logo" />
              <h1 className="text-[11px] text-gray-600 font-bold leading-[1.1] text-center">NCMC</h1>
            </Link> */}
            {/* <Link to="/comingsoon" className="bg-white gap-2 p-1 h-[72px] lg:h-[120px] flex flex-col items-center justify-center rounded px-2">
              <img src="/categories/ncmc.png" className="h-[28px] grayscale opacity-65" alt="logo" />
              <h1 className="text-[11px] text-gray-600 font-bold leading-[1.1] text-center">NPS</h1>
            </Link> */}
          </div>
        </div>
        {/* Utilities */}
        <div>
          <h2 className="appTextColor font-bold mb-2 text-[16px]">Utilities</h2>
          <div className="w-full grid grid-cols-4 lg:grid-cols-8 gap-1.5 select-none rounded">
            <Link to="/operator?category=Postpaid&sub_category=Non BBPS" className="bg-white gap-1 p-1 h-[72px] lg:h-[120px] flex flex-col items-center justify-center rounded px-2">
              <PhoneIphoneIcon style={{ fontSize: "25px", color: "gray" }} />
              <h1 className="text-[11px] text-gray-600 font-bold text-center leading-[1.1]">Mobile Postpaid</h1>
            </Link>
            <Link to="/operator?category=Gas&sub_category=BBPS" className="bg-white gap-2 p-1 h-[72px] lg:h-[120px] flex flex-col items-center justify-center rounded px-2">
              <LocalGasStationOutlinedIcon style={{ fontSize: "25px", color: "gray" }} />
              <h1 className="text-[11px] text-gray-600 font-bold leading-[1.1] text-center">Piped Gas</h1>
            </Link>
            <Link to="/operator?category=Water&sub_category=BBPS" className="bg-white gap-2 p-1 h-[72px] lg:h-[120px] flex flex-col items-center justify-center rounded px-2">
              <WaterDropOutlinedIcon style={{ fontSize: "25px", color: "gray" }} />
              <h1 className="text-[11px] text-gray-600 font-bold">Water Bill</h1>
            </Link>
            <Link to="/operator?category=Electricity&sub_category=BBPS" className="bg-white gap-2 p-1 h-[72px] lg:h-[120px] flex flex-col items-center justify-center rounded px-2">
              <LightbulbOutlinedIcon style={{ fontSize: "25px", color: "gray" }} />
              <h1 className="text-[11px] text-gray-600 font-bold leading-[1.1] text-center">Electricity Bill</h1>
            </Link>
            <Link to="/operator?category=Broadband Postpaid&sub_category=BBPS" className="bg-white gap-2 p-1 h-[72px] lg:h-[120px] flex flex-col items-center justify-center rounded px-2">
              <RouterOutlinedIcon style={{ fontSize: "25px", color: "gray" }} />
              <h1 className="text-[11px] text-gray-600 font-bold leading-[1.1] text-center">Broadband Postpaid</h1>
            </Link>
            <Link to="/operator?category=LPG Gas&sub_category=BBPS" className="bg-white gap-2 p-1 h-[72px] lg:h-[120px] flex flex-col items-center justify-center rounded px-2">
              <GasMeterOutlinedIcon style={{ fontSize: "25px", color: "gray" }} />
              <h1 className="text-[11px] text-gray-600 font-bold l text-center">LPG Gas</h1>
            </Link>
            <Link to="/operator?category=Landline Postpaid&sub_category=BBPS" className="bg-white gap-2 p-1 h-[72px] lg:h-[120px] flex flex-col items-center justify-center rounded px-2">
              <TbDeviceLandlinePhone style={{ fontSize: "25px", color: "gray" }} />
              <h1 className="text-[11px] text-gray-600 font-bold leading-[1.1] text-center">Landline Postpaid</h1>
            </Link>
          </div>
        </div>
        <div>
          <h2 className="appTextColor font-bold mb-2 text-[16px]">Financial Services & Taxes</h2>
          <div className="w-full grid grid-cols-4 lg:grid-cols-8 gap-1.5 select-none rounded">
            <Link to="/operator?category=Insurance&sub_category=BBPS" className="bg-white gap-2 p-1 h-[72px] lg:h-[120px] flex flex-col items-center justify-center rounded px-2">
              <ShieldOutlinedIcon style={{ fontSize: "25px", color: "gray" }} />
              <h1 className="text-[11px] text-gray-600 font-bold leading-[1.1] text-center">Insurance</h1>
            </Link>
            <Link to="/operator?category=Municipal Taxes&sub_category=BBPS" className="bg-white gap-1 p-1 h-[72px] lg:h-[120px] flex flex-col items-center justify-center rounded px-2">
              <DomainOutlinedIcon style={{ fontSize: "25px", color: "gray" }} />
              <h1 className="text-[11px] text-gray-600 font-bold leading-[1.1] text-center">Municipal Taxes</h1>
            </Link>
            <Link to="/operator?category=Municipal Services&sub_category=BBPS" className="bg-white gap-1 p-1 h-[72px] lg:h-[120px] flex flex-col items-center justify-center rounded px-2">
              <CorporateFareIcon style={{ fontSize: "25px", color: "gray" }} />
              <h1 className="text-[11px] text-gray-600 font-bold leading-[1.1] text-center">Municipal Services</h1>
            </Link>
            <Link to="/operator?category=Recurring Deposit&sub_category=BBPS" className="bg-white gap-1 p-1 h-[72px] lg:h-[120px] flex flex-col items-center justify-center rounded px-2">
              <CalendarMonthIcon style={{ fontSize: "25px", color: "gray" }} />
              <h1 className="text-[11px] text-gray-600 font-bold leading-[1.1] text-center">Recurring Deposit</h1>
            </Link>
            <Link to="/operator?category=Loan Repayment&sub_category=BBPS" className="bg-white gap-2 p-1 h-[72px] lg:h-[120px] flex flex-col items-center justify-center rounded px-2">
              <PaymentsOutlinedIcon style={{ fontSize: "25px", color: "gray" }} />
              <h1 className="text-[11px] text-gray-600 font-bold  leading-[1.1] text-center">Loan Payment</h1>
            </Link>
            {/* <Link to="/comingsoon" className="bg-white gap-1 p-1 h-[72px] lg:h-[120px] flex flex-col items-center justify-center rounded px-2">
              <img src="/categories/ncmc.png" className="h-[28px] grayscale opacity-65" alt="logo" />
              <h1 className="text-[11px] text-gray-600 font-bold leading-[1.1] text-center">Rental</h1>
            </Link> */}
            <Link to="/operator?category=Credit Card&sub_category=BBPS" className="bg-white gap-2 p-1 h-[72px] lg:h-[120px] flex flex-col items-center justify-center rounded px-2">
              <FiCreditCard style={{ fontSize: "25px", color: "gray" }} />
              <h1 className="text-[11px] text-gray-600 font-bold leading-[1.1] text-center">Credit Card</h1>
            </Link>
          </div>
        </div>
        <div className="pb-2">
          <h2 className="appTextColor font-bold mb-2 text-[16px]">More Services</h2>
          <div className="w-full grid grid-cols-4 lg:grid-cols-8 gap-1.5 select-none rounded">
            <Link to="/operator?category=Clubs & Associations&sub_category=BBPS" className="bg-white gap-1 p-1 h-[72px] lg:h-[120px] flex flex-col items-center justify-center rounded">
              <GroupsOutlinedIcon style={{ fontSize: "25px", color: "gray" }} />
              <h1 className="text-[11px] text-gray-600 font-bold leading-[1.1] text-center">Club & Associations</h1>
            </Link>
            <Link to="/operator?category=Hospital&sub_category=BBPS" className="bg-white gap-2 p-1 h-[72px] lg:h-[120px] flex flex-col items-center justify-center rounded px-2">
              <LocalHospitalOutlinedIcon style={{ fontSize: "25px", color: "gray" }} />
              <h1 className="text-[11px] text-gray-600 font-bold">Hospital</h1>
            </Link>
            <Link to="/operator?category=Education Fees&sub_category=BBPS" className="bg-white gap-2 p-1 h-[72px] lg:h-[120px] flex flex-col items-center justify-center rounded px-2">
              <LibraryBooksOutlinedIcon style={{ fontSize: "25px", color: "gray" }} />
              <h1 className="text-[11px] text-gray-600 font-bold leading-[1.1] text-center">Education Fees</h1>
            </Link>
            <Link to="/operator?category=Health Insurance&sub_category=BBPS" className="bg-white gap-2 p-1 h-[72px] lg:h-[120px] flex flex-col items-center justify-center rounded px-2">
              <HealthAndSafetyOutlinedIcon style={{ fontSize: "25px", color: "gray" }} />
              <h1 className="text-[11px] text-gray-600 font-bold leading-[1.1] text-center">Health Insurance</h1>
            </Link>
            <Link to="/operator?category=Hospital & Pathology&sub_category=BBPS" className="bg-white gap-1 p-1 h-[72px] lg:h-[120px] flex flex-col items-center justify-center rounded px-2">
              <BiotechOutlinedIcon style={{ fontSize: "25px", color: "gray" }} />
              <h1 className="text-[11px] text-gray-600 font-bold leading-[1.1] text-center">Hospital & Pathology</h1>
            </Link>
            <Link to="/operator?category=Housing Society&sub_category=BBPS" className="bg-white gap-1 p-1 h-[72px] lg:h-[120px] flex flex-col items-center justify-center rounded px-2">
              <Diversity2OutlinedIcon style={{ fontSize: "25px", color: "gray" }} />
              <h1 className="text-[11px] text-gray-600 font-bold leading-[1.1] text-center">Housing Society</h1>
            </Link>
            <Link to="/operator?category=Life Insurance&sub_category=BBPS" className="bg-white gap-2 p-1 h-[72px] lg:h-[120px] flex flex-col items-center justify-center rounded px-2">
              <Diversity1OutlinedIcon style={{ fontSize: "25px", color: "gray" }} />
              <h1 className="text-[11px] text-gray-600 font-bold leading-[1.1] text-center">Life Insurance</h1>
            </Link>
            {/* <Link to="/comingsoon" className="bg-white gap-2 p-1 h-[72px] lg:h-[120px] flex flex-col items-center justify-center rounded px-2">
              <Inventory2OutlinedIcon style={{ fontSize: "25px", color: "gray" }} />
              <h1 className="text-[11px] text-gray-600 font-bold leading-[1.1] text-center">Donation</h1>
            </Link> */}
            <Link to="/operator?category=Subscription&sub_category=BBPS" className="bg-white gap-2 p-1 h-[72px] lg:h-[120px] flex flex-col items-center justify-center rounded px-2">
              <SubscriptionsOutlinedIcon style={{ fontSize: "25px", color: "gray" }} />
              <h1 className="text-[11px] text-gray-600 font-bold">Subscription</h1>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SeeMore;
