import React, { useEffect } from "react";
import { FaArrowLeft } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
const ComingSoon = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();
  const handleBack = () => {
    navigate(-1);
  };
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-[#681667] text-white">
      <button onClick={handleBack} className="fixed top-3 left-3">
        <FaArrowLeft size={25} />
      </button>
      <h1 className="text-4xl md:text-6xl font-bold mb-4">Coming Soon</h1>
      <p className="text-lg md:text-xl text-center mb-8 max-w-md">We’re working hard to bring you something amazing! Stay tuned and check back soon.</p>
    </div>
  );
};

export default ComingSoon;
