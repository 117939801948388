import React, { useEffect, useState } from "react";
import { FaAddressBook, FaArrowLeft } from "react-icons/fa";
import { PiDotsNineBold } from "react-icons/pi";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Splided from "../Components/Splided";

const Prepaid = () => {
  const navigate = useNavigate();
  const [mobileNumber, setMobileNumber] = useState("");
  const [loadingButton, setLoadingButton] = useState(false);

  // Scroll to top on component load
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const continueButton = async () => {
    if (/^[6789]\d{9}$/.test(mobileNumber)) {
      setLoadingButton(true);
      try {
        // Simulate API call delay
        await new Promise((resolve) => setTimeout(resolve, 1000));
        navigate(`/rechargePlans?number=${mobileNumber}`);
      } catch (error) {
        toast.error("Something went wrong. Please try again.", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
        });
      } finally {
        setLoadingButton(false);
      }
    } else {
      toast.error("Please enter a valid mobile number.", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
      });
    }
  };

  // Function to trigger native contact picker
  const openContactPicker = () => {
    if (window.AndroidInterface) {
      window.AndroidInterface.openContactPicker();
    } else {
      toast.error("Contact list is not permissible.");
    }
  };

  // Handle incoming contact number from Android
  useEffect(() => {
    const handleMessage = (event) => {
      const contactNumber = event.data;
      if (/^\d{10}$/.test(contactNumber)) {
        setMobileNumber(contactNumber);
      } else {
        console.warn("Invalid contact number received.");
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  const handleMobileNumber = (event) => {
    const value = event.target.value;
    if (/^\d{0,10}$/.test(value)) {
      setMobileNumber(value);
    }
  };

  return (
    <div className="h-screen flex flex-col bg-slate-200 justify-start">
      <div className="h-[60px] w-full appBgColor flex items-center px-3 gap-3 text-white">
        <Link to="/home">
          <FaArrowLeft size={20} />
        </Link>
        <h1 className="font-bold select-none">Prepaid</h1>
      </div>
      <div className="px-1 md:px-4 md:flex items-start justify-center md:w-full">
        <div className="md:w-[40%]">
          <form className="flex flex-col p-2 py-[30px]" onSubmit={(e) => e.preventDefault()}>
            <div className="space-y-4">
              <div className="relative flex items-center">
                <input type="text" placeholder="Mobile Number" value={mobileNumber} onChange={handleMobileNumber} className="w-full py-[12px] px-[35px] rounded-md outline-none border-2 appBorderColor" maxLength={10} required />
                <FaAddressBook onClick={openContactPicker} className="absolute right-2 appTextColor cursor-pointer" size={20} />
                <PiDotsNineBold className="absolute left-2 appTextColor cursor-pointer" size={20} />
              </div>
            </div>
            <div className="mt-6">
              <button
                type="button"
                onClick={continueButton}
                className="appBgColor text-white w-full py-[12px] rounded-lg select-none"
                disabled={loadingButton} // Disable button if loading
              >
                {loadingButton ? (
                  <div className="flex items-center justify-center">
                    <div className="w-4 h-4 border-2 border-white border-t-transparent rounded-full animate-spin"></div>
                    <span className="ml-2">Processing...</span>
                  </div>
                ) : (
                  <>Continue</>
                )}
              </button>
            </div>
          </form>
          <div className="px-2">
            <div className="bg-white p-2 rounded-md select-none">
              <h1 className="text-[14px]">Once a payment is successfully processed and added to your account, it cannot be reversed or refunded. For more detailed information, please refer to our refund policy.</h1>
            </div>
          </div>
        </div>
        <div className="hidden md:block md:w-[60%] md:p-[20px] lg:p-[30px]">
          <Splided />
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Prepaid;
