import React, { useEffect, useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Loading from "../Components/Loading";
import Error from "../Components/Error"; // Import your Error component
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const FundRequest = () => {
  const base_url = process.env.REACT_APP_BASE_URL;
  const key = localStorage.getItem("key");
  const [loading, setLoading] = useState(true);
  const [loadingButton, setLoadingButton] = useState(false);
  const [error, setError] = useState(false); // Error state
  const [banks, setBanks] = useState([]);
  const [formData, setFormData] = useState({
    requestAmount: "",
    transactionReference: "",
    providerId: "",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();
  useEffect(() => {
    const fetchBanks = async () => {
      try {
        const response = await fetch(`${base_url}app/active-gateway.php?key=${key}`);

        if (!response.ok) {
          throw new Error("Failed to fetch bank options");
        }
        const result = await response.json();

        if (result.status) {
          setBanks(result.data1 || []);
        } else {
          setError(true);
        }
      } catch (error) {
        console.error("Error fetching banks:", error.message);
        setError(true);
      } finally {
        setLoading(false);
      }
    };
    fetchBanks();
  }, [base_url, key]);
  const handleBack = () => {
    navigate(-1);
  };
  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setFormData({
      ...formData,
      [name]: files ? files[0] : value,
    });
  };
  const handleSubmit = async () => {
    const { requestAmount, transactionReference, providerId } = formData;
    if (!requestAmount || !transactionReference || !providerId) {
      toast.error("Please fill in all required fields!");
      return;
    }
    setLoadingButton(true);
    try {
      const response = await fetch(`${base_url}app/fund-request.php?key=${key}&amount=${requestAmount}&utr=${transactionReference}&provider_id=${providerId}`);
      const result = await response.json();
      if (!response.ok || !result.status) {
        throw new Error(result.message || "Failed to submit the fund request");
      }
      toast.success(result.message || "Fund request submitted successfully!");
      setFormData({
        requestAmount: "",
        transactionReference: "",
        providerId: "",
      });
    } catch (error) {
      setError(true);
      console.error(error.message || "Something went wrong!");
    } finally {
      setLoadingButton(false);
    }
  };
  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <Error title={"Something went wrong"} description={"Api fetching error"} />;
  }
  return (
    <div className="bg-slate-200 select-none min-h-screen">
      <div className="h-[60px] w-full appBgColor flex items-center px-3 gap-3 text-white sticky top-0 z-50">
        <button onClick={handleBack} aria-label="Go Back">
          <FaArrowLeft size={20} />
        </button>
        <h1 className="font-bold">Fund Request</h1>
      </div>
      <form>
        <div className="px-3 mt-4">
          <div>
            <label htmlFor="requestAmount" className="text-[16px] font-semibold">
              Amount
            </label>
            <input type="number" name="requestAmount" value={formData.requestAmount} onChange={handleChange} className="border-2 appBorderColor p-2 py-[12px] mb-3 w-full mt-1 outline-none rounded-md" placeholder="Enter Amount" required />
          </div>
          <div>
            <label htmlFor="transactionReference" className="text-[16px] font-semibold">
              Transaction Reference
            </label>
            <input type="text" name="transactionReference" value={formData.transactionReference} onChange={handleChange} className="border-2 appBorderColor p-2 py-[12px] mb-3 w-full mt-1 outline-none rounded-md" placeholder="Enter transaction ID" required />
          </div>
          <div className="mb-4">
            <label htmlFor="providerId" className="text-[16px] font-semibold">
              Choose Gateway/Bank
            </label>
            <select name="providerId" value={formData.providerId} onChange={handleChange} className="w-full mt-1 px-4 py-[13px] border-2 rounded-md focus:outline-none appBorderColor bg-white" required>
              <option value="">Select an option</option>
              {banks.map((bank) => (
                <option key={bank.provider_id} value={bank.provider_id}>
                  {bank.provider_name}
                </option>
              ))}
            </select>
          </div>
          <div className="mt-6">
            <button type="button" onClick={handleSubmit} className="appBgColor text-white w-full py-[12px] rounded-lg select-none" disabled={loadingButton}>
              {loadingButton ? (
                <div className="flex items-center justify-center">
                  <div className="w-4 h-4 border-2 border-white border-t-transparent rounded-full animate-spin"></div>
                  <span className="ml-2">Processing...</span>
                </div>
              ) : (
                "Submit"
              )}
            </button>
          </div>
        </div>
      </form>
      <ToastContainer />
    </div>
  );
};

export default FundRequest;
