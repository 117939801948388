import React, { useEffect } from "react";

const ProcessingActivity = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    const preventNavigation = (event) => {
      event.preventDefault();
      event.stopPropagation();
      window.history.pushState(null, "", window.location.href);
    };

    window.history.pushState(null, "", window.location.href);
    window.addEventListener("popstate", preventNavigation);
    window.addEventListener("beforeunload", preventNavigation);
    return () => {
      window.removeEventListener("popstate", preventNavigation);
      window.removeEventListener("beforeunload", preventNavigation);
    };
  }, []);

  return (
    <div className="fixed inset-0 bg-blue-500 flex flex-col items-center justify-center z-50 text-white">
      <div className="w-16 h-16 border-4 border-white border-t-transparent rounded-full animate-spin"></div>

      <div className="mt-6 text-center">
        <h1 className="text-xl font-semibold">Processing...</h1>
        <p className="mt-2 text-sm">Hold on while we process the request details...</p>
      </div>
    </div>
  );
};

export default ProcessingActivity;
