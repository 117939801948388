import React, { useState, useEffect } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { MdOutlineCurrencyRupee } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loading from "../Components/Loading";
import SuccessActivity from "../Components/SuccessActivity";
import FailedActivity from "../Components/FailedActivity";
import ProcessingActivity from "../Components/ProcessingActivity";
import Error from "../Components/Error";
import PendingActivity from "../Components/PendingActivity";
function BillFetch() {
  const base_url = process.env.REACT_APP_BASE_URL;
  const key = localStorage.getItem("key");
  const [loading, setLoading] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [billInfo, setBillInfo] = useState(null);
  const [operatorIcon, setOperatorIcon] = useState("");
  const [amount, setAmount] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const [activityStatus, setActivityStatus] = useState(null);
  const { formData, operator_id } = location.state || {};
  const [error, setError] = useState(false);
  const [response_msg, SetResponseMsg] = useState("");
  const [response_order_id, SetOrderId] = useState("");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const queryParams = new URLSearchParams({
          key,
          operator_id,
          ...formData,
        }).toString();
        const response = await fetch(`${base_url}app/operator_bill_fetch.php?${queryParams}`);
        if (!response.ok) {
          throw new Error("Failed to fetch user data");
        }
        const data = await response.json();
        if (data.status === "Success") {
          setBillInfo({
            ...data.data1,
            additionalData: data.data2,
          });

          setOperatorIcon(data.data3);
          setAmount(data.data1.billnetamount);
        } else {
          toast.error("Something went wrong");
        }
      } catch (err) {
        toast.error("Something went wrong");
      } finally {
        setLoading(false);
      }
    };

    if (formData && operator_id && base_url && key) fetchData();
  }, [base_url, key, formData, operator_id]);

  useEffect(() => {
    const fetchData = async () => {
      try {
      } catch (error) {
        toast.error("Something went wrong");
        setError(true);
      }
    };
    fetchData();
  }, []);

  const handlePayNow = async () => {
    setLoadingButton(true);
    setActivityStatus("processing");
    if (!parseFloat(amount) > 0) {
      toast.error("Enter valid amount");
      setActivityStatus(null);
      return;
    }
    try {
      const queryParams = new URLSearchParams({
        key,
        operator_id,
        ...formData,
      }).toString();
      const response = await fetch(`${base_url}app/universal_payment.php?${queryParams}&amount=${amount}`);
      const data = await response.json();
      if (data.status === "Success") {
        setActivityStatus("success");
        SetResponseMsg(data.message);
        SetOrderId(data.data2);
      } else if (data.status === "Failed") {
        setActivityStatus("failed");
        SetResponseMsg(data.message);
        SetOrderId(data.data2);
      } else {
        SetResponseMsg(data.message);
        SetOrderId(data.data2);
      }
    } catch (error) {
      setActivityStatus("pending");
      SetResponseMsg("Transaction timeout, please check the report");
    } finally {
      setLoadingButton(false);
    }
  };
  const handleBack = () => {
    navigate(-1);
  };
  return (
    <>
      {activityStatus === "processing" && <ProcessingActivity />}
      {activityStatus === "success" && <SuccessActivity message={response_msg} order_id={response_order_id} />}
      {activityStatus === "failed" && <FailedActivity message={response_msg} order_id={response_order_id} />}
      {activityStatus === "pending" && <PendingActivity message={response_msg} order_id={response_order_id} />}
      {error ? (
        <Error />
      ) : (
        activityStatus === null &&
        (loading ? (
          <Loading />
        ) : (
          <div className="text-white h-screen bg-slate-200">
            <div className="bg-[#681667] px-2 py-4 pb-[40px] space-y-7 sticky top-0">
              <header className="flex items-center gap-3">
                <button onClick={handleBack}>
                  <FaArrowLeft size={20} />
                </button>
                <button className="text-[20px] font-semibold">Bill Fetch</button>
              </header>
              <div className="mt-4 px-1 flex items-center justify-between">
                <div className="space-y-1">
                  <h2 className="text-xl font-bold">{billInfo?.userName || "N/A"}</h2>
                  <h1 className="text-[15px]">
                    Your bill for {billInfo?.cellNumber}, amount {billInfo?.billAmount} due by {billInfo?.dueDate}
                  </h1>
                </div>
                <img src={operatorIcon} alt="Operator" className="h-[50px] w-[50px] rounded-full bg-white" />
              </div>
            </div>
            <div className="py-4 px-2">
              <div className="bg-white p-2 rounded-md space-y-3">
                {billInfo && billInfo.additionalData && (
                  <>
                    <div className="bg-white p-2 rounded-md space-y-3">
                      {billInfo && billInfo.additionalData && (
                        <>
                          <div className="flex items-start w-[100%] justify-between text-black border-b pb-1">
                            <h1 className="text-[13px] w-[40%]">Biller name</h1>
                            <h1 className="text-[13px] w-[60%] text-end">{billInfo.additionalData}</h1>
                          </div>
                          <div className="flex items-center justify-between text-black border-b pb-1">
                            <h1 className="text-[13px]">Available bal</h1>
                            <h1 className="text-[13px]">{billInfo.balance}</h1>
                          </div>
                          <div className="flex items-center justify-between text-black border-b pb-1">
                            <h1 className="text-[13px]">Bill date</h1>
                            <h1 className="text-[13px]">{billInfo.dueDate}</h1>
                          </div>
                          <div className="flex items-center justify-between text-black border-b pb-1">
                            <h1 className="text-[13px]">Next recharge</h1>
                            <h1 className="text-[13px]">{billInfo.nextRecharge}</h1>
                          </div>
                          <div className="flex items-start w-[100%] justify-between text-black border-b pb-1">
                            <h1 className="text-[13px] w-[30%]">Plan</h1>
                            <h1 className="text-[13px] w-[70%] pl-[60px] text-center lg:pl-[420px]">{billInfo.planName}</h1>
                          </div>
                          <div className="flex items-center justify-between text-black border-b pb-1">
                            <h1 className="text-[13px]">Net amount</h1>
                            <h1 className="text-[13px]">{billInfo.billAmount}</h1>
                          </div>
                          <div className="flex items-center justify-between text-black border-b pb-1">
                            <h1 className="text-[13px]">Status</h1>
                            <h1 className="text-[13px]">{billInfo.status}</h1>
                          </div>
                        </>
                      )}
                    </div>
                  </>
                )}
              </div>
              <div className="my-4 relative">
                <input
                  type="number"
                  placeholder="Enter amount"
                  value={amount}
                  readOnly={billInfo && !billInfo.acceptPartPay}
                  className={`w-full px-4 py-3 text-black bg-white rounded-lg border-2 border-[#681667] outline-none pr-9 ${billInfo && !billInfo.acceptPartPay ? "bg-gray-100 cursor-not-allowed" : ""}`}
                  onChange={(e) => setAmount(e.target.value)}
                />
                <MdOutlineCurrencyRupee className="absolute right-3 top-3" color="gray" size={25} />
              </div>
              <button type="button" className="bg-[#681667] text-white w-full py-[12px] rounded-lg" onClick={handlePayNow}>
                {loadingButton ? (
                  <div className="flex items-center justify-center">
                    <div className="w-4 h-4 border-2 border-white border-t-transparent rounded-full animate-spin"></div>
                    <span className="ml-2">Processing...</span>
                  </div>
                ) : (
                  <> PAY NOW</>
                )}
              </button>
              <div className="mt-8">
                <div className="bg-white p-2 rounded-md select-none text-black">
                  <h1 className="text-[14px]">Once a payment is successfully processed and added to your account, it cannot be reversed or refunded. For more detailed information, please refer to our refund policy.</h1>
                </div>
              </div>
            </div>
          </div>
        ))
      )}
      <ToastContainer />
    </>
  );
}

export default BillFetch;
