import React, { useEffect, useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
const AddMoney = () => {
  const base_url = process.env.REACT_APP_BASE_URL;
  const key = localStorage.getItem("key");
  const [amount, setAmount] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const response = await fetch(`${base_url}app/payment.php?key=${key}&amount=${amount}`);
      const data = await response.json();

      if (data.status === "Success") {
        window.open(data.data1, "_blank", "noopener,noreferrer");
      } else {
        console.error("Failed to fetch profile data:", data.message);
      }
    } catch (error) {
      console.error("Error fetching profile data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div className="bg-slate-200 h-screen">
      <div className="h-[60px] w-full appBgColor flex items-center px-3 gap-3 text-white">
        <button onClick={handleBack}>
          <FaArrowLeft size={20} />
        </button>
        <h1 className="font-bold select-none">Add Money</h1>
      </div>
      <div>
        <form onSubmit={handleSubmit} className="flex flex-col h-full p-2 px-3 py-[30px] gap-6">
          <div className="space-y-1">
            <div className="flex relative">
              <input type="number" placeholder="Amount" value={amount} onChange={(e) => setAmount(Number(e.target.value))} className="w-full p-2 py-[12px] border-2 appBorderColor rounded-lg mb-2 ps-8" />
              <span className="text-[22px] font-semibold absolute top-2 left-5 opacity-70">₹</span>
            </div>
            <div className="flex items-center justify-between">
              <button type="button" onClick={() => setAmount(500)} className="border-2 appBorderColor rounded px-3 py-1.5 text-[14px] flex items-center justify-center font-semibold bg-white">
                +₹500
              </button>
              <button type="button" onClick={() => setAmount(1000)} className="border-2 appBorderColor rounded px-3 py-1.5 text-[14px] flex items-center justify-center font-semibold bg-white">
                +₹1000
              </button>
              <button type="button" onClick={() => setAmount(3000)} className="border-2 appBorderColor rounded px-3 py-1.5 text-[14px] flex items-center justify-center font-semibold bg-white">
                +₹3000
              </button>
              <button type="button" onClick={() => setAmount(5000)} className="border-2 appBorderColor rounded px-3 py-1.5 text-[14px] flex items-center justify-center font-semibold bg-white">
                +₹5000
              </button>
            </div>
          </div>
          <div>
            <button type="submit" className="appBgColor text-white w-full py-[12px] mt-2 rounded-lg" disabled={loading}>
              {loading ? (
                <div className="flex items-center justify-center">
                  <div className="w-4 h-4 border-2 border-white border-t-transparent rounded-full animate-spin"></div>
                  <span className="ml-2">Processing...</span>
                </div>
              ) : (
                "PAY NOW"
              )}
            </button>
          </div>
        </form>
        <div className="p-2 px-3">
          <div className="bg-white p-2 rounded-md select-none">
            <h1 className="text-[13px]">Once a payment is successfully processed and added to your account, it cannot be reversed or refunded. For more detailed information, please refer to our refund policy.</h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddMoney;
