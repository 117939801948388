import React, { useState, useEffect } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { MdEdit } from "react-icons/md";
import { RxCross2 } from "react-icons/rx";
import { Link, useSearchParams } from "react-router-dom";
import Loading from "../Components/Loading";
import LoadingData from "../Components/LoadingData";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MdOutlineShareLocation } from "react-icons/md";
import { FaIndianRupeeSign } from "react-icons/fa6";
import SuccessActivity from "../Components/SuccessActivity";
import ProcessingActivity from "../Components/ProcessingActivity";
import FailedActivity from "../Components/FailedActivity";
import LoadingActivity from "../Components/LoadingActivity";
import PendingActivity from "../Components/PendingActivity";

function RechargePlans() {
  const base_url = process.env.REACT_APP_BASE_URL;
  const key = localStorage.getItem("key");
  const [searchParams] = useSearchParams();
  const number = searchParams.get("number");

  const [operator, setOperator] = useState("");
  const [region, setRegion] = useState("");
  const [operatorCode, setOperatorCode] = useState("");
  const [regionCode, setRegionCode] = useState("");
  const [plansData, setPlansData] = useState({});
  const [activeTab, setActiveTab] = useState(""); // To track active tab
  const [filteredPlans, setFilteredPlans] = useState([]); // For filtering plans
  const [loading, setLoading] = useState(true);
  const [loadingData, setLoadingData] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedAmount, setSelectedAmount] = useState(null);
  const [operatorIcon, setOperatorIcon] = useState("");
  const [modal, setModal] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [onTransactionProcessing, setOnTransactionProcessing] = useState(false);
  const [onTransactionFailure, setOnTransactionFailure] = useState(false);
  const [onTransactionSuccess, setOnTransactionSuccess] = useState(false);
  const [onTransactionPending, setOnTransactionPending] = useState(false);
  const [loadingActivity, setLoadingActivity] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [response_msg, SetResponseMsg] = useState("");
  const [response_order_id, SetOrderId] = useState("");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${base_url}app/fetch_op_state.php?key=${key}&number=${number}`);
        const data = await response.json();
        if (data.status === "Success") {
          setOperator(data.data2);
          setRegion(data.data1);
          setOperatorCode(data.data4);
          setRegionCode(data.data3);
          fetchPlans(data.data3, data.data4);
        } else {
          toast.error("Failed to fetch data:", data.message);
        }
      } catch (error) {
        toast.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [key, number]);

  const fetchPlans = async (regionCode, operatorCode) => {
    setLoadingData(true);
    try {
      const response = await fetch(`${base_url}app/plans.php?key=${key}&circle_code=${regionCode}&operator_id=${operatorCode}`);
      const data = await response.json();
      if (data.status === "Success") {
        setOperatorIcon(data.data2);
        setPlansData(data.data1.records);
        setActiveTab(Object.keys(data.data1.records)[0]);
      } else {
        toast.error(data.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
        });
      }
    } catch (error) {
      toast.error(error.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
      });
    } finally {
      setLoadingData(false);
    }
  };

  useEffect(() => {
    if (activeTab && !isSearching) {
      setFilteredPlans(plansData[activeTab] || []);
    }
  }, [activeTab, plansData, isSearching]);

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchValue(value);

    if (value.trim() === "") {
      setIsSearching(false);
      setFilteredPlans(plansData[activeTab] || []);
    } else {
      setIsSearching(true);
      const allPlans = Object.values(plansData).flat();
      const filtered = allPlans.filter((plan) => plan.amount.toString().includes(value.trim()));
      setFilteredPlans(filtered);
    }
  };

  const handlePlanClick = (amount) => {
    setSelectedAmount(amount);
    setLoadingActivity(true);
    document.body.style.overflow = "hidden";
    setTimeout(() => {
      setLoadingActivity(false);
      setIsModalOpen(true);
      document.body.style.overflow = "";
    }, 1500);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedAmount(0);
  };

  const handleSaveSelection = async () => {
    if (operatorCode && regionCode) {
      setOperator(operator);
      setRegion(region);
      setModal(false);

      await fetchPlans(regionCode, operatorCode);
    } else {
      toast.error("Select both operator and circle.");
    }
  };

  const handleEditModal = () => {
    setModal(true);
  };

  const handlePayNow = async (event) => {
    event.preventDefault();
    setLoadingButton(true);
    setOnTransactionProcessing(true);

    try {
      const response = await fetch(`${base_url}app/universal_payment.php?key=${key}&amount=${selectedAmount}&number=${number}&operator_id=${operatorCode}`);

      const data = await response.json();

      if (data.status === "Success") {
        setOnTransactionSuccess(true);
        SetResponseMsg(data.data1);
        SetOrderId(data.data2);
      } else if (data.status === "Failed") {
        setOnTransactionFailure(true);
        SetResponseMsg(data.message);
        SetOrderId(data.data2);
      } else {
        setOnTransactionPending(true);
        SetResponseMsg(data.message);
        SetOrderId(data.data2);
      }
    } catch (error) {
      setOnTransactionPending(true);
      SetResponseMsg(error);
    } finally {
      setLoadingButton(false);
      setOnTransactionProcessing(false);
      setIsModalOpen(false);
    }
  };
  useEffect(() => {
    if (isModalOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isModalOpen]);

  return (
    <div className="bg-slate-200 min-h-screen">
      {loading ? (
        <Loading />
      ) : onTransactionProcessing ? (
        <ProcessingActivity />
      ) : onTransactionSuccess ? (
        <SuccessActivity message={response_msg} order_id={response_order_id} />
      ) : onTransactionFailure ? (
        <FailedActivity message={response_msg} order_id={response_order_id} />
      ) : loadingActivity ? (
        <LoadingActivity message={response_msg} order_id={response_order_id} />
      ) : onTransactionPending ? (
        <PendingActivity />
      ) : (
        <div className="text-white bg-slate-200 sticky top-0">
          <div className="appBgColor px-3 py-4 pb-[40px] space-y-7">
            <header className="flex items-center gap-4">
              <Link to="/prepaid">
                <FaArrowLeft size={20} />
              </Link>
              <button className="text-[20px] font-semibold">Form fills</button>
            </header>
            <div className="mt-4 px-1 flex items-center justify-between">
              <div className="space-y-1">
                <h2 className="text-xl font-bold">+91 {number}</h2>
                <div className="flex items-center gap-2">
                  <p className="text-sm">{operator && region ? `${operator} - ${region}` : "Loading operator and region..."}</p>
                  <MdEdit size={18} onClick={handleEditModal} />
                </div>
              </div>
              <img src={`${operatorIcon}`} className="h-[50px] w-[50px] rounded-full bg-white" />
            </div>
          </div>
          <div className="px-3 py-5 bg-slate-200">
            <div className="relative">
              <span className="absolute inset-y-0 left-3 flex items-center pointer-events-none">
                <svg className="h-5 w-5 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-4.35-4.35M16.65 10a6.65 6.65 0 11-13.3 0 6.65 6.65 0 0113.3 0z" />
                </svg>
              </span>
              <input type="number" placeholder="Search amount" className="w-full pl-10 pr-4 py-3 text-black bg-white rounded-md border-2 appBorderColor outline-none" value={searchValue} onChange={handleSearch} />
            </div>
          </div>
          {modal && (
            <div className="fixed inset-0 bg-black bg-opacity-80 flex justify-center items-center" onClick={() => setModal(false)}>
              <div className="bg-white px-3 py-4 rounded-lg w-full max-w-[400px] h-full" onClick={(e) => e.stopPropagation()}>
                {/* Dropdowns for operator and region */}
                <div className="space-y-4">
                  <div>
                    <label htmlFor="operator" className="block text-sm font-semibold text-black pb-1">
                      Select Operator
                    </label>
                    <select
                      id="operator"
                      value={operatorCode}
                      onChange={(e) => {
                        const operatorName = e.target.options[e.target.selectedIndex].text;
                        setOperator(operatorName);
                        setOperatorCode(e.target.value);
                      }}
                      className="w-full py-3 border-2 appBorderColor rounded-md outline-none text-black"
                    >
                      <option value="" disabled>
                        Select an operator
                      </option>
                      <option value="100">Airtel</option>
                      <option value="101">Jio</option>
                      <option value="102">Vi</option>
                      <option value="103">BSNL</option>
                    </select>
                  </div>
                  <div>
                    <label htmlFor="circle" className="block text-sm font-semibold text-black pb-1">
                      Select Circle
                    </label>
                    <select
                      id="circle"
                      value={regionCode}
                      onChange={(e) => {
                        const circleName = e.target.options[e.target.selectedIndex].text;
                        setRegion(circleName);
                        setRegionCode(e.target.value);
                      }}
                      className="w-full py-3 border-2 appBorderColor rounded-md outline-none text-black"
                    >
                      <option value="" disabled>
                        --Select Circle--
                      </option>
                      <option value="1">Andhra Pradesh</option>
                      <option value="2">Assam</option>
                      <option value="3">Bihar & Jharkhand</option>
                      <option value="3">Bihar Jharkhand</option>
                      <option value="4">Chennai</option>
                      <option value="5">Delhi & NCR</option>
                      <option value="5">Delhi NCR</option>
                      <option value="6">Gujarat</option>
                      <option value="7">Haryana</option>
                      <option value="8">Himachal Pradesh</option>
                      <option value="9">Jammu & Kashmir</option>
                      <option value="10">Karnataka</option>
                      <option value="11">Kerala</option>
                      <option value="12">Kolkata</option>
                      <option value="13">Maharashtra & Goa (except Mumbai)</option>
                      <option value="13">Maharashtra</option>
                      <option value="13">Goa</option>
                      <option value="14">MP & Chhattisgarh</option>
                      <option value="14">MP</option>
                      <option value="14">Chhattisgarh</option>
                      <option value="14">Madhya Pradesh Chhattisgarh</option>
                      <option value="14">Madhya Pardesh</option>
                      <option value="15">Mumbai</option>
                      <option value="16">North East</option>
                      <option value="17">Orissa</option>
                      <option value="18">Punjab</option>
                      <option value="19">Rajasthan</option>
                      <option value="20">Tamilnadu</option>
                      <option value="20">Tamil Nadu</option>
                      <option value="20">Telangana</option>
                      <option value="21">UP (East)</option>
                      <option value="21">UP East</option>
                      <option value="22">UP (West) & Uttarakhand</option>
                      <option value="22">UP West</option>
                      <option value="22">Uttarakhand</option>
                      <option value="23">West Bengal</option>
                      <option value="51">All India (except Delhi/Mumbai)</option>
                      <option value="51">All Circles</option>
                    </select>
                  </div>
                </div>

                {/* Save Changes button */}
                <button onClick={handleSaveSelection} className="mt-4 w-full py-3 appBgColor text-white rounded-md">
                  Save Changes
                </button>
              </div>
            </div>
          )}
          {/* Tabs */}
          {!isSearching && (
            <div className="flex pb-2 w-full overflow-x-scroll px-3 no-scrollbar">
              {Object.keys(plansData).map((tab) => (
                <button key={tab} className={`text-black py-1.5 px-4 rounded-md text-nowrap ${activeTab === tab ? "appBgColor text-white" : "bg-transparent"}`} onClick={() => setActiveTab(tab)}>
                  {tab}
                </button>
              ))}
            </div>
          )}
          {/* Plans */}
        </div>
      )}
      {/* Plan Details Modal */}
      {isModalOpen && selectedAmount && (
        <div className="bg-white p-6 sticky bottom-0 rounded-lg w-full max-w-[400px]" onClick={(e) => e.stopPropagation()}>
          <div className="flex justify-between items-center">
            <h3 className="text-xl font-semibold">{selectedAmount}</h3>
            <button onClick={handleCloseModal} className="text-gray-500 text-xl">
              <RxCross2 />
            </button>
          </div>
          <p className="text-gray-700 mt-4">{selectedAmount}</p>
          <div className="fixed inset-0 bg-black bg-opacity-80 flex justify-center items-end" onClick={handleCloseModal}>
            <div className="bg-white py-4 px-3 rounded-t-lg w-full max-w-[400px] " onClick={(e) => e.stopPropagation()}>
              <div className="flex justify-end items-center mb-4">
                <button onClick={handleCloseModal} className="text-gray-500 text-xl">
                  <RxCross2 />
                </button>
              </div>
              <div className="space-y-4">
                <div className="flex items-center gap-2">
                  <input type="text" placeholder="Enter value 1" className="w-full px-4 py-3 border-2 appBorderColor rounded-lg outline-none" readOnly value={operator} />
                  <input type="text" placeholder="Enter value 2" className="w-full px-4 py-3 border-2 appBorderColor rounded-lg outline-none" readOnly value={number} />
                </div>
                <div className="flex items-center relative">
                  <input type="text" placeholder="Enter value 3" className="w-full px-4 py-3 border-2 appBorderColor rounded-lg outline-none pr-10" readOnly value={region} />
                  <MdOutlineShareLocation size={28} className="absolute right-3 text-gray-400" />
                </div>
                <div className="flex items-center relative">
                  <input type="text" placeholder="Enter value 4" className="w-full px-4 py-3 border-2 appBorderColor rounded-lg outline-none pr-10" readOnly value={selectedAmount} />
                  <FaIndianRupeeSign size={25} className="absolute right-3 text-gray-400" />
                </div>
                <button className="w-full appBgColor text-white py-3 rounded-lg mt-2" onClick={handlePayNow} disabled={loadingButton}>
                  {loadingButton ? (
                    <div className="flex items-center justify-center">
                      <div className="w-4 h-4 border-2 border-white border-t-transparent rounded-full animate-spin"></div>
                      <span className="ml-2">Processing...</span>
                    </div>
                  ) : (
                    <>PAY NOW</>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <div>
        {loadingData ? (
          <LoadingData />
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-2 bg-slate-200 pt-2 px-3 pb-4">
            {filteredPlans.map((plan, index) => (
              <div key={index} className="px-4 py-2 bg-white shadow-md rounded-md cursor-pointer" onClick={() => handlePlanClick(plan.amount)}>
                <div className="flex items-center justify-between border-b-2 pb-1">
                  <h1 className="appTextColor font-bold text-[13px]">
                    ₹<span>{plan.amount}</span>
                  </h1>
                  <div>
                    <h2 className="text-black text-center text-[14px]">Data</h2>
                    <h2 className="appTextColor font-bold text-[13px]">{plan.Data}</h2>
                  </div>
                  <div>
                    <h2 className="text-black text-[14px]">Validity</h2>
                    <h2 className="appTextColor font-bold text-[13px]">{plan.Validity}</h2>
                  </div>
                </div>
                <p className="text-[13px] text-gray-500">{plan.planDescription}</p>
              </div>
            ))}
          </div>
        )}
      </div>

      <ToastContainer />
    </div>
  );
}

export default RechargePlans;
