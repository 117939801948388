import React, { useEffect, useState } from "react";
import "../App.css";
import { MdOutlineAccountBalanceWallet } from "react-icons/md";
import { FaRupeeSign, FaPlus } from "react-icons/fa";
import { Link } from "react-router-dom";
import Header from "../Components/Header";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { FiMessageSquare } from "react-icons/fi";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import LiveTvIcon from "@mui/icons-material/LiveTv";
import SmartDisplayOutlinedIcon from "@mui/icons-material/SmartDisplayOutlined";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import DirectionsCarFilledOutlinedIcon from "@mui/icons-material/DirectionsCarFilledOutlined";
import GasMeterOutlinedIcon from "@mui/icons-material/GasMeterOutlined";
import FeedRoundedIcon from "@mui/icons-material/FeedRounded";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import DeveloperModeOutlinedIcon from "@mui/icons-material/DeveloperModeOutlined";
import IntegrationInstructionsOutlinedIcon from "@mui/icons-material/IntegrationInstructionsOutlined";
import EventNoteRoundedIcon from "@mui/icons-material/EventNoteRounded";
import VolunteerActivismOutlinedIcon from "@mui/icons-material/VolunteerActivismOutlined";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function Home() {
  const base_url = process.env.REACT_APP_BASE_URL;
  const key = localStorage.getItem("key");
  const [walletBalance, setWalletBalance] = useState("0.00");
  const [referCode, setReferCode] = useState("");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await fetch(`${base_url}app/balance.php?key=${key}`);
        if (response.ok) {
          const data = await response.json();
          if (data.status === "Success") {
            setWalletBalance(data.data1 || "0.00");
            setReferCode(data.data2 || "N/A");
          } else {
            toast.error("Slow internet connection");
          }
        }
      } catch (error) {
        toast.error("Slow internet connection");
      }
    };
    fetchUserData();
  }, [base_url, key]);
  return (
    <div className="pt-[62px] bg-slate-200 min-h-screen">
      <Header />
      <div className="carousel">
        <div className="splide">
          <Splide
            options={{
              pagination: true,
              arrows: false,
              drag: true,
              autoplay: false,
              interval: 3000,
              speed: 1000,
            }}
          >
            <SplideSlide>
              <a href="https://api.whatsapp.com/send?phone=917382056372" target="_blank" rel="noopener noreferrer">
                <img src="/main.png" alt="carousel" className="h-[170px] md:h-[300px] w-full " />
              </a>
            </SplideSlide>
            <SplideSlide>
              <a href="https://api.whatsapp.com/send?phone=917382056372" target="_blank" rel="noopener noreferrer">
                <img src="/main.png" alt="carousel" className="h-[170px] md:h-[300px] w-full " />
              </a>
            </SplideSlide>
          </Splide>
        </div>
      </div>
      <div className="w-full bg-slate-200 py-1 pt-3 px-3 select-none">
        <div className="h-full w-full appBgColor rounded-lg flex items-center justify-between ps-2 py-5">
          <div className="flex items-center justify-between text-white gap-2">
            <MdOutlineAccountBalanceWallet size={45} />
            <div>
              <h3 className="text-[13px] font-bold">Wallet Balance</h3>
              <div className="flex items-center justify-start">
                <FaRupeeSign />
                <h1 className="font-bold text-[18px]">{walletBalance}</h1>
              </div>
            </div>
          </div>
          <Link to="/addMoney" className="flex items-center gap-2 bg-white px-[8px] py-[6px] addMoney">
            <FaPlus className="opacity-70" />
            <h1 className="font-semibold">Add Money</h1>
          </Link>
        </div>
      </div>
      <div className="w-full bg-slate-200 pt-2 px-3 pb-1 select-none">
        <a href="https://api.whatsapp.com/send?phone=917382056372" className="h-[45px] w-full bg-green-100 rounded-md  px-3 text-green-600 gap-4 py-4 flex items-center justify-center" target="_blank" rel="noopener noreferrer" aria-label="Connect with support via WhatsApp">
          <FiMessageSquare />
          <h1>Need help? Let's connect with support</h1>
        </a>
      </div>
      <div className="bg-slate-200 w-full p-2 px-3 grid grid-cols-4 lg:grid-cols-8 gap-1.5 select-none">
        <Link to="/prepaid" className="bg-white gap-1 p-1 h-[72px] md:h-[120px] flex flex-col items-center justify-center rounded px-3">
          <PhoneAndroidIcon style={{ fontSize: "25px", color: "gray" }} />
          <h1 className="text-[11px] text-gray-600 font-bold text-center leading-[1.1]">Mobile Prepaid</h1>
        </Link>
        <Link to="/operator?category=Postpaid&sub_category=Non BBPS" className="bg-white gap-1 p-1 h-[72px] md:h-[120px] flex flex-col items-center justify-center rounded px-3">
          <PhoneIphoneIcon style={{ fontSize: "25px", color: "gray" }} />
          <h1 className="text-[11px] text-gray-600 font-bold text-center leading-[1.1]">Mobile Postpaid</h1>
        </Link>
        <Link to="/operator?category=Dth&sub_category=Non BBPS" className="bg-white gap-2 p-1 h-[72px] md:h-[120px] flex flex-col items-center justify-center rounded">
          <LiveTvIcon style={{ fontSize: "25px", color: "gray" }} />
          <h1 className="text-[11px] text-gray-600 font-bold">DTH</h1>
        </Link>
        <Link to="/operator?category=Redeem code&sub_category=Non BBPS" className="bg-white gap-1 p-1 h-[72px] md:h-[120px] flex flex-col items-center justify-center rounded px-3">
          <SmartDisplayOutlinedIcon style={{ fontSize: "25px", color: "gray" }} />
          <h1 className="text-[11px] text-gray-600 font-bold text-center leading-[1.1]">Redeem Code</h1>
        </Link>
        <Link to="/operator?category=Electricity&sub_category=BBPS" className="bg-white gap-2 p-1 h-[72px] md:h-[120px] flex flex-col items-center justify-center rounded">
          <LightbulbOutlinedIcon style={{ fontSize: "25px", color: "gray" }} />
          <h1 className="text-[11px] text-gray-600 font-bold">Electricity</h1>
        </Link>
        <Link to="/operator?category=Fastag&sub_category=BBPS" className="bg-white gap-2 p-1 h-[72px] md:h-[120px] flex flex-col items-center justify-center rounded">
          <DirectionsCarFilledOutlinedIcon style={{ fontSize: "25px", color: "gray" }} />
          <h1 className="text-[11px] text-gray-600 font-bold">Fastag</h1>
        </Link>
        <Link to="/operator?category=LPG Gas&sub_category=BBPS" className="bg-white gap-2 p-1 h-[72px] md:h-[120px] flex flex-col items-center justify-center rounded">
          <GasMeterOutlinedIcon style={{ fontSize: "25px", color: "gray" }} />
          <h1 className="text-[11px] text-gray-600 font-bold">LPG Gas</h1>
        </Link>
        <Link to="/seeMore" className="bg-white gap-2 p-1 h-[72px] md:h-[120px] flex flex-col items-center justify-center rounded">
          <img src="/categories/more.gif" className="h-[28px]" alt="logo" />
          <h1 className="text-[11px] text-gray-600 font-bold">See more</h1>
        </Link>
      </div>
      <div className="w-full bg-slate-200 pt-1 px-3">
        <div className="h-full w-full appBgColor rounded-md flex items-center justify-between px-3 text-white gap-4 py-3">
          <div className="space-y-1">
            <h1 className="text-[16px] font-bold select-none">Refer & Earn</h1>
            <h2 className="text-[10px] select-none">Share Recharge Save with your friends and get up-to 20, when they add 199 or more.</h2>
            <h1 className="text-[16px] font-bold select-none">Refer code: {referCode}</h1>
          </div>
          <a href={`https://api.whatsapp.com/send?text=Use my referral code ${referCode} to get rewards on Recharge Save! Download the app here: https://rechargesave.in/share.php?code=${referCode}`} target="_blank" rel="noopener noreferrer" className="bg-white p-3 rounded">
            <img src="/share.png" alt="logo" className="h-[18px] w-[22px]" />
          </a>
        </div>
      </div>
      <div className="w-full bg-slate-200 p-2 px-3 grid grid-cols-4 lg:grid-cols-8 pb-6 pt-3 gap-1.5 select-none">
        <Link to="/dayBook" className="bg-white gap-2 p-1 h-[72px] md:h-[120px] flex flex-col items-center justify-center rounded">
          <EventNoteRoundedIcon style={{ fontSize: "25px", color: "gray" }} />
          <h1 className="text-[11px] text-gray-600 font-bold">Day Book</h1>
        </Link>
        <Link to="/profile" className="bg-white gap-2 p-1 h-[72px] md:h-[120px] flex flex-col items-center justify-center rounded">
          <AccountCircleRoundedIcon style={{ fontSize: "25px", color: "gray" }} />
          <h1 className="text-[11px] text-gray-600 font-bold">Profile</h1>
        </Link>
        <Link to="/margin" className="bg-white gap-2 p-1 h-[72px] md:h-[120px] flex flex-col items-center justify-center rounded">
          <BarChartOutlinedIcon style={{ fontSize: "25px", color: "gray" }} />
          <h1 className="text-[11px] text-gray-600 font-bold">Margin</h1>
        </Link>
        <Link to="/report" className="bg-white gap-2 p-1 h-[72px] md:h-[120px] flex flex-col items-center justify-center rounded">
          <FeedRoundedIcon style={{ fontSize: "25px", color: "gray" }} />
          <h1 className="text-[11px] text-gray-600 font-bold ">Report</h1>
        </Link>
        <Link to="/Setting" className="bg-white gap-2 p-1 h-[72px] md:h-[120px] flex flex-col items-center justify-center rounded">
          <SettingsRoundedIcon style={{ fontSize: "25px", color: "gray" }} />
          <h1 className="text-[11px] text-gray-600 font-bold">Setting</h1>
        </Link>
        <Link to="/fundRequest" className="bg-white gap-1 p-1 h-[72px] md:h-[120px] flex flex-col items-center justify-center rounded px-3">
          <VolunteerActivismOutlinedIcon style={{ fontSize: "25px", color: "gray" }} />
          <h1 className="text-[11px] text-gray-600 font-bold text-center leading-[1.1]">Fund Request</h1>
        </Link>
        <Link to="/apiconfiguration" className="bg-white gap-1 p-1 h-[72px] md:h-[120px] flex flex-col items-center justify-center rounded">
          <IntegrationInstructionsOutlinedIcon style={{ fontSize: "25px", color: "gray" }} />
          <h1 className="text-[11px] text-gray-600 font-bold text-center leading-[1.1]">Api Configuration</h1>
        </Link>
        <Link to="/documentation" className="bg-white gap-2 p-1 h-[72px] md:h-[120px] flex flex-col items-center justify-center rounded">
          <DeveloperModeOutlinedIcon style={{ fontSize: "25px", color: "gray" }} />
          <h1 className="text-[11px] text-gray-600 font-bold">Documentation</h1>
        </Link>
      </div>
      <ToastContainer />
    </div>
  );
}

export default Home;
