import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";

function Error({ title, description }) {
  const handleReload = () => {
    window.location.reload();
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();
  const handleBack = () => {
    navigate(-1);
  };
  return (
    <div className="h-screen w-full flex flex-col items-center justify-center bg-red-500 text-white p-4 relative">
      <div className="h-[60px] flex items-center px-2 gap-3 text-white absolute top-0 left-2">
        <button onClick={handleBack}>
          <FaArrowLeft size={20} />
        </button>
      </div>
      <FontAwesomeIcon icon={faExclamationTriangle} className="text-6xl mb-4" />
      <h1 className="text-[20px] font-bold mb-2">{title}</h1>
      <p className="text-center max-w-md leading-tight text-[14px]">{description}</p>
      <button
        className="bg-blue-600 px-4 py-2 rounded mt-6"
        onClick={handleReload} // Add onClick handler
      >
        Try again
      </button>
    </div>
  );
}

export default Error;
