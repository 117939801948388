import React, { useEffect, useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loading from "../Components/Loading";

function Documentation() {
  const base_url = process.env.REACT_APP_BASE_URL;
  const key = localStorage.getItem("key");
  const [apiData, setApiData] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const handleBack = () => {
    navigate(-1);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setLoading(true);

    setTimeout(() => {
      setApiData({});
      setLoading(false);
    }, 1500);
  }, []);
  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center ">
        <Loading />
      </div>
    );
  }
  return (
    <div className="bg-slate-200 min-h-screen">
      <div className="h-[60px] w-full appBgColor flex items-center px-3 gap-3 text-white sticky top-0">
        <button onClick={handleBack}>
          <FaArrowLeft size={20} />
        </button>
        <h1 className="font-bold">Documentation</h1>
      </div>
      <div className="p-3">
        <div className="w-full bg-white rounded-md px-4 py-3">
          <h1 className="text-[16px] font-bold underline">Recharge API</h1>
          <h2 className="font-semibold">URL</h2>
          <p className="mb-3">{base_url}api/recharge.php</p>
          <h2 className="font-semibold">Request</h2>
          <div className="overflow-x-auto bg-slate-100">
            <pre className="p-2 rounded mb-3">?key=777&amp;amount=10&amp;operator_id=100</pre>
          </div>
          <h2 className="font-semibold">Successful Response</h2>
          <div className="overflow-x-auto bg-green-100">
            <pre className=" p-2 rounded mb-3">
              {JSON.stringify(
                {
                  status: "Failed",
                  message: "Your transaction got failed due to inactive operator. Please contact support team.",
                  suggestion: "",
                  data1: "",
                  data2: "173365263997916",
                  data3: "",
                  data4: "",
                  data5: "",
                },
                null,
                2
              )}
            </pre>
          </div>
          <h2 className="font-semibold">Pending Response</h2>
          <div className="overflow-x-auto bg-yellow-100">
            <pre className="p-2 rounded mb-3">
              {JSON.stringify(
                {
                  status: "Pending",
                  message: "Your transaction is still under process. Please wait for confirmation.",
                },
                null,
                2
              )}
            </pre>
          </div>
          <h2 className="font-semibold">Failure Response</h2>
          <div className="overflow-x-auto bg-red-100 ">
            <pre className="p-2 rounded">
              {JSON.stringify(
                {
                  status: "Failed",
                  message: "The transaction failed due to an invalid amount or operator.",
                },
                null,
                2
              )}
            </pre>
          </div>
        </div>
      </div>
      <div className="p-4">
        <div className="w-full bg-white rounded-md px-4 py-3">
          <h1 className="text-[16px] font-bold underline">Balance API</h1>
          <h2 className="font-semibold">URL</h2>
          <p className="mb-3">{base_url}api/recharge.php</p>
          <h2 className="font-semibold">Request</h2>
          <div className="overflow-x-auto bg-slate-100">
            <pre className="p-2 rounded mb-3">?key=777&amp;amount=10&amp;operator_id=100</pre>
          </div>
          <h2 className="font-semibold">Successful Response</h2>
          <div className="overflow-x-auto bg-green-100">
            <pre className=" p-2 rounded mb-3">
              {JSON.stringify(
                {
                  status: "Failed",
                  message: "Your transaction got failed due to inactive operator. Please contact support team.",
                  suggestion: "",
                  data1: "",
                  data2: "173365263997916",
                  data3: "",
                  data4: "",
                  data5: "",
                },
                null,
                2
              )}
            </pre>
          </div>
          <h2 className="font-semibold">Pending Response</h2>
          <div className="overflow-x-auto bg-yellow-100">
            <pre className="p-2 rounded mb-3">
              {JSON.stringify(
                {
                  status: "Pending",
                  message: "Your transaction is still under process. Please wait for confirmation.",
                },
                null,
                2
              )}
            </pre>
          </div>
          <h2 className="font-semibold">Failure Response</h2>
          <div className="overflow-x-auto bg-red-100 ">
            <pre className="p-2 rounded">
              {JSON.stringify(
                {
                  status: "Failed",
                  message: "The transaction failed due to an invalid amount or operator.",
                },
                null,
                2
              )}
            </pre>
          </div>
        </div>
      </div>
      <div className="p-4">
        <div className="w-full bg-white rounded-md px-4 py-3">
          <h1 className="text-[16px] font-bold underline">Status API</h1>
          <h2 className="font-semibold">URL</h2>
          <p className="mb-3">{base_url}api/recharge.php</p>
          <h2 className="font-semibold">Request</h2>
          <div className="overflow-x-auto bg-slate-100">
            <pre className="p-2 rounded mb-3">?key=777&amp;amount=10&amp;operator_id=100</pre>
          </div>
          <h2 className="font-semibold">Successful Response</h2>
          <div className="overflow-x-auto bg-green-100">
            <pre className=" p-2 rounded mb-3">
              {JSON.stringify(
                {
                  status: "Failed",
                  message: "Your transaction got failed due to inactive operator. Please contact support team.",
                  suggestion: "",
                  data1: "",
                  data2: "173365263997916",
                  data3: "",
                  data4: "",
                  data5: "",
                },
                null,
                2
              )}
            </pre>
          </div>
          <h2 className="font-semibold">Pending Response</h2>
          <div className="overflow-x-auto bg-yellow-100">
            <pre className="p-2 rounded mb-3">
              {JSON.stringify(
                {
                  status: "Pending",
                  message: "Your transaction is still under process. Please wait for confirmation.",
                },
                null,
                2
              )}
            </pre>
          </div>
          <h2 className="font-semibold">Failure Response</h2>
          <div className="overflow-x-auto bg-red-100 ">
            <pre className="p-2 rounded">
              {JSON.stringify(
                {
                  status: "Failed",
                  message: "The transaction failed due to an invalid amount or operator.",
                },
                null,
                2
              )}
            </pre>
          </div>
        </div>
      </div>
      <div className="p-4">
        <div className="w-full bg-white rounded-md px-4 py-3">
          <h1 className="text-[16px] font-bold underline">Complaint API</h1>
          <h2 className="font-semibold">URL</h2>
          <p className="mb-3">{base_url}api/recharge.php</p>
          <h2 className="font-semibold">Request</h2>
          <div className="overflow-x-auto bg-slate-100">
            <pre className="p-2 rounded mb-3">?key=777&amp;amount=10&amp;operator_id=100</pre>
          </div>
          <h2 className="font-semibold">Successful Response</h2>
          <div className="overflow-x-auto bg-green-100">
            <pre className=" p-2 rounded mb-3">
              {JSON.stringify(
                {
                  status: "Failed",
                  message: "Your transaction got failed due to inactive operator. Please contact support team.",
                  suggestion: "",
                  data1: "",
                  data2: "173365263997916",
                  data3: "",
                  data4: "",
                  data5: "",
                },
                null,
                2
              )}
            </pre>
          </div>
          <h2 className="font-semibold">Pending Response</h2>
          <div className="overflow-x-auto bg-yellow-100">
            <pre className="p-2 rounded mb-3">
              {JSON.stringify(
                {
                  status: "Pending",
                  message: "Your transaction is still under process. Please wait for confirmation.",
                },
                null,
                2
              )}
            </pre>
          </div>
          <h2 className="font-semibold">Failure Response</h2>
          <div className="overflow-x-auto bg-red-100 ">
            <pre className="p-2 rounded">
              {JSON.stringify(
                {
                  status: "Failed",
                  message: "The transaction failed due to an invalid amount or operator.",
                },
                null,
                2
              )}
            </pre>
          </div>
        </div>
      </div>
      <div className="p-4">
        <div className="w-full bg-white rounded-md px-4 py-3">
          <h1 className="text-[16px] font-bold underline">Complaint Status API</h1>
          <h2 className="font-semibold">URL</h2>
          <p className="mb-3">{base_url}api/recharge.php</p>
          <h2 className="font-semibold">Request</h2>
          <div className="overflow-x-auto bg-slate-100">
            <pre className="p-2 rounded mb-3">?key=777&amp;amount=10&amp;operator_id=100</pre>
          </div>
          <h2 className="font-semibold">Successful Response</h2>
          <div className="overflow-x-auto bg-green-100">
            <pre className=" p-2 rounded mb-3">
              {JSON.stringify(
                {
                  status: "Failed",
                  message: "Your transaction got failed due to inactive operator. Please contact support team.",
                  suggestion: "",
                  data1: "",
                  data2: "173365263997916",
                  data3: "",
                  data4: "",
                  data5: "",
                },
                null,
                2
              )}
            </pre>
          </div>
          <h2 className="font-semibold">Pending Response</h2>
          <div className="overflow-x-auto bg-yellow-100">
            <pre className="p-2 rounded mb-3">
              {JSON.stringify(
                {
                  status: "Pending",
                  message: "Your transaction is still under process. Please wait for confirmation.",
                },
                null,
                2
              )}
            </pre>
          </div>
          <h2 className="font-semibold">Failure Response</h2>
          <div className="overflow-x-auto bg-red-100 ">
            <pre className="p-2 rounded">
              {JSON.stringify(
                {
                  status: "Failed",
                  message: "The transaction failed due to an invalid amount or operator.",
                },
                null,
                2
              )}
            </pre>
          </div>
        </div>
      </div>
      <div className="p-4">
        <div className="w-full bg-white rounded-md px-4 py-3">
          <h1 className="text-[16px] font-bold underline">Callback API</h1>
          <h2 className="font-semibold">URL</h2>
          <p className="mb-3">Your callback url</p>
          <h2 className="font-semibold">Send data</h2>
          <div className="overflow-x-auto bg-slate-100">
            <pre className="p-2 rounded mb-3">?key=777&amp;amount=10&amp;operator_id=100</pre>
          </div>
        </div>
      </div>
      <div className="p-4">
        <table className="min-w-full table-auto border-collapse border border-gray-300">
          <thead>
            <tr className="bg-green-600 text-white">
              <th className="px-4 py-2 text-left text-sm font-semibold border border-gray-300">Operator</th>
              <th className="px-4 py-2 text-left text-sm font-semibold border border-gray-300">Operator Id</th>
            </tr>
          </thead>
          <tbody>
            <tr className="bg-white">
              <td className="px-4 py-2 text-sm text-gray-700 border border-gray-300">Airtel</td>
              <td className="px-4 py-2 text-sm text-gray-700 border border-gray-300">47367</td>
            </tr>
          </tbody>
        </table>
      </div>
      <ToastContainer />
    </div>
  );
}

export default Documentation;
