import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

function FailedActivity({ message, order_id }) {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);
  const handleReceipt = () => {
    navigate(`/transactionStatus?order_id=${order_id}`);
  };

  return (
    <div className="bg-red-600 h-screen flex flex-col items-center justify-center px-4">
      <div className="border-b-2 pb-4 w-full">
        <div className="flex flex-col items-center justify-center">
          <div className="flex items-center justify-center bg-white rounded-full h-20 w-20">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-16 w-16 text-white bg-red-600 rounded-full" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <circle cx="12" cy="12" r="" />
              <line x1="15" y1="9" x2="9" y2="15" />
              <line x1="9" y1="9" x2="15" y2="15" />
            </svg>
          </div>
          <p className="mt-4 text-white text-lg font-bold select-none">Transaction Failed</p>
          <p className="text-sm mt-2 text-white text-center select-none">{message}</p>
        </div>
      </div>
      <div className="pt-5 flex items-center gap-4">
        <button className="bg-blue-600 text-white px-5 py-2 rounded select-none" onClick={handleReceipt}>
          VIEW RECEIPT
        </button>
        <Link to="/home" className="bg-yellow-600 text-white px-5 py-2 rounded select-none">
          CLOSE
        </Link>
      </div>
    </div>
  );
}

export default FailedActivity;
