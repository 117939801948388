import React, { useEffect, useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import Loading from "../Components/Loading";
import Error from "../Components/Error";

const Margin = () => {
  const base_url = process.env.REACT_APP_BASE_URL;
  const key = localStorage.getItem("key");
  const [margins, setMargins] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${base_url}app/operator.php?key=${key}&category=&sub_category=Non BBPS`);
        const data = await response.json();
        if (data.status === "Success") {
          setMargins(data.data1);
        }
      } catch (error) {
        console.error("Error fetching margin data:", error);
        setError(true);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);
  const handleBack = () => {
    navigate(-1);
  };
  return (
    <>
      {loading ? (
        <Loading />
      ) : error ? (
        <Error title={"Something went wrong"} description={"api fetching error"} />
      ) : (
        <div className="bg-slate-200 select-none min-h-screen">
          <div className="h-[60px] w-full appBgColor flex items-center px-3 gap-3 text-white sticky top-0">
            <button onClick={handleBack}>
              <FaArrowLeft size={20} />
            </button>
            <h1 className="font-bold select-none">Margin</h1>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 md:gap-2 px-3 lg:px-4 md:pt-1 lg:pt-2 pb-3">
            {margins.map((item) => (
              <div key={item.operator_id} className="bg-white mt-[6px] md:mt-0 flex items-center justify-between px-3 py-3 md:py-5 lg:py-6 select-none rounded-lg">
                <div className="flex items-center gap-5">
                  <img src={item.operator_logo} alt={`${item.biller_name} logo`} className="h-[35px] w-[35px]" />
                  <div>
                    <h1 className="font-bold">{item.biller_name}</h1>
                    <h2 className="text-[12px]">{item.biller_category}</h2>
                  </div>
                </div>
                <h2 className="bg-zinc-200 px-2 py-1 rounded text-[13px]  flex items-center justify-center">
                  {item.commission} {item.commission_type === "%" ? "%" : "Flat"}
                </h2>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default Margin;
