import React from "react";
import { useNavigate } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";

const RefundPolicy = () => {
  const navigate = useNavigate();
  const handleBack = () => {
    navigate(-1);
  };
  return (
    <div>
      <div className="h-[60px] w-full bg-[#681667] flex items-center px-2 gap-3 text-white sticky top-0">
        <button onClick={handleBack}>
          <FaArrowLeft size={20} />
        </button>
        <h1 className="font-bold select-none">Refund Policy</h1>
      </div>
      <div className="page-container">
        <div style={{ margin: "10px" }}>
          <h3 style={{ color: "var(--app-color)", marginBottom: "5px", textDecoration: "underline" }}>Refund Policy for Recharge Save</h3>
          <p>In case of the wrong operator number, we can't refund the amount if the ''number'' was valid and recharge has already been done.</p>
          <p style={{ marginTop: "10px" }}>If any user wants to close their account they should finish their Recharge Save wallet balance before closing, Because we can't transfer their Recharge Save wallet balance to bank account.</p>
          <p style={{ marginTop: "10px" }}>In case of any pending or processing payment, it may take upto 7 working days to reflect on his/her Recharge Save wallet or may refund to his/her bank account.</p>
        </div>
      </div>
    </div>
  );
};

export default RefundPolicy;
