import React, { useState, useEffect } from "react";
import { AnimatePresence } from "framer-motion";
import { Link, useNavigate } from "react-router-dom";
import Loading from "../Components/Loading";
import { FaArrowLeft } from "react-icons/fa";
const User = () => {
  const base_url = process.env.REACT_APP_BASE_URL;
  const key = localStorage.getItem("key");
  const [userName, setUserName] = useState(localStorage.getItem("name") || "No Name");
  const [isVisible, setIsVisible] = useState(true);
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const handleBack = () => {
    setIsVisible(false);
    setTimeout(() => {
      navigate(-1);
    }, 300);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (!localStorage.getItem("name")) {
      const fetchUserName = async () => {
        try {
          const response = await fetch(`${base_url}app/get_profile.php?key=${key}`);
          if (response.ok) {
            const data = await response.json();
            const fetchedName = data.name || "User";
            setUserName(fetchedName);
            localStorage.setItem("name", fetchedName);
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      };

      fetchUserName();
    }
  }, [base_url, key]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await fetch(`${base_url}app/profile_report.php?key=${key}`);
        if (!response.ok) {
          throw new Error("Failed to fetch user data");
        }
        const data = await response.json();
        setUserData(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [base_url, key]);

  return (
    <AnimatePresence>
      {loading && <Loading />}
      {!loading && error && <p className="p-3 text-center text-red-500">{error}</p>}
      {isVisible && (
        <div>
          <div className="min-h-screen bg-slate-200">
            <div className="h-[60px] w-full appBgColor flex items-center px-3 gap-3 text-white">
              <Link to="/home">
                <FaArrowLeft size={20} />
              </Link>
              <h1 className="font-bold">Welcome</h1>
            </div>
            <div className="flex flex-col items-center justify-center py-1 appBgColor pb-2">
              <div className="h-[100px] w-[100px] bg-blue-500 rounded-full relative flex items-center justify-center">
                <h1 className="text-[30px] text-white font-bold select-none">DP</h1>
              </div>
              <h1 className="text-[22px] font-bold select-none text-white py-2">{userName}</h1>

              <input id="fileInput" type="file" accept="image/*" className="hidden" />
              <input id="fileInputSelfie" type="file" accept="image/*" className="hidden" capture="environment" />
            </div>

            <>
              {!loading && !error && userData && (
                <>
                  <div className="p-3 flex items-center justify-between border-b-2 border-slate-200 py-4 bg-white">
                    <div>
                      <h1 className="text-[13px] opacity-60">Added Balance</h1>
                      <div className="flex items-center gap-2 pt-1">
                        <img src="/wallet.png" className="h-[28px]" alt="logo" />
                        <p className="font-bold">₹{userData.data3 || "0.00"}</p>
                      </div>
                    </div>
                    <Link to="/addMoney" className="appBgColor text-white px-3 py-1 rounded">
                      Add Money
                    </Link>
                  </div>
                  <div className="p-3 border-b-2 border-slate-200 select-none bg-white">
                    <h1 className="text-[13px] opacity-60">Rewards</h1>
                    <p className="font-semibold mt-1 opacity-60">₹{userData.data4 || "0.00"}</p>
                  </div>
                  <div className="p-3 flex items-center justify-between border-b-2 border-slate-200 py-4 select-none bg-white">
                    <div>
                      <h1 className="text-[13px] opacity-60">Total Sell</h1>
                      <div className="flex items-center gap-2 pt-1">
                        <p className="font-bold opacity-60">₹{userData.data1 || "0.00"}</p>
                      </div>
                    </div>
                    <div>
                      <h1 className="text-[13px] opacity-60">Total Earn</h1>
                      <p className="font-semibold mt-1 opacity-60">₹{userData.data2 || "0.00"}</p>
                    </div>
                  </div>
                  <div className="p-3 flex items-center justify-between border-b-2 border-slate-200 bg-white py-4 select-none">
                    <div>
                      <h1 className="text-[13px] opacity-60">Distributor Program</h1>
                      <h1 className="font-bold">Earn unlimited cashback.</h1>
                    </div>
                    <a href="https://api.whatsapp.com/send?phone=917382056372" target="_blank" rel="noopener noreferrer" className="appBgColor text-white px-3 py-1 rounded">
                      Join Now
                    </a>
                  </div>
                </>
              )}
            </>
          </div>
        </div>
      )}
    </AnimatePresence>
  );
};

export default User;
