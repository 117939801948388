import React, { useEffect } from "react";
import { motion } from "framer-motion";
import { Link, useNavigate } from "react-router-dom";
function SuccessActivity({ message, order_id }) {
  const navigate = useNavigate();
  const variants = {
    hidden: { y: "100%", opacity: 0 },
    visible: { y: 0, opacity: 1 },
    exit: { y: "100%", opacity: 0 },
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  const handleReceipt = () => {
    navigate(`/transactionStatus?order_id=${order_id}`);
  };

  return (
    <motion.div className="bg-[#007e24] h-screen w-full flex flex-col items-center justify-center px-4" initial="hidden" animate="visible" exit="exit" transition={{ type: "tween", duration: 0.5 }} variants={variants}>
      <div className="border-b-2 w-full pb-4">
        <div className="flex flex-col items-center">
          <div className="bg-white p-3 rounded-full">
            <svg xmlns="http://www.w3.org/2000/svg" className="w-20 h-20 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
              <circle cx="12" cy="12" r="10" className="stroke-current fill-current" />
              <path d="M9 12l2 2 4-4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="stroke-white" />
            </svg>
          </div>
          <h1 className="mt-4 text-xl font-bold text-white">Transaction is success</h1>
          <p className="text-sm mt-2 text-white">Txn Id {message}</p>
        </div>
      </div>
      <div className="pt-5 flex items-center gap-4">
        <button className="bg-blue-600 text-white px-5 py-2 rounded" onClick={handleReceipt}>
          VIEW RECEIPT
        </button>
        <Link to="/home" className="bg-red-600 text-white px-5 py-2 rounded">
          CLOSE
        </Link>
      </div>
    </motion.div>
  );
}
export default SuccessActivity;
