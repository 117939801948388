import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import Home from "./pages/Home";
import AddMoney from "./pages/AddMoney";
import Layout from "./pages/components/Layout";
import Prepaid from "./pages/Prepaid";
import Setting from "./pages/Setting";
import Profile from "./pages/Profile";
import Margin from "./pages/Margin";
import User from "./pages/User";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import ComingSoon from "./pages/ComingSoon";
import TransactionStatus from "./pages/TransactionStatus";
import RechargePlans from "./pages/RechargePlans";
import OperatorForm from "./pages/OperatorForm";
import Operator from "./pages/Operator";
import BillFetch from "./pages/BillFetch";
import SeeMore from "./pages/SeeMore";
import DayBook from "./pages/DayBook";
import FundRequest from "./pages/FundRequest";
import Report from "./pages/Report";
import ApiConfiguration from "./pages/ApiConfiguration";
import Documentation from "./pages/Documentation";
import RedirectPage from "./pages/RedirectPage";
import Terms from "./pages/Terms";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import RefundPolicy from "./pages/RefundPolicy";
import AccountDeletion from "./pages/AccountDeletion";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Login />} />
        <Route path="/home" element={<Home />} />
        <Route path="addMoney" element={<AddMoney />} />
        <Route path="signup" element={<Signup />} />
        <Route path="prepaid" element={<Prepaid />} />
        <Route path="operator" element={<Operator />} />
        <Route path="operatorForm" element={<OperatorForm />} />
        <Route path="comingSoon" element={<ComingSoon />} />
        <Route path="setting" element={<Setting />} />
        <Route path="profile" element={<Profile />} />
        <Route path="margin" element={<Margin />} />
        <Route path="report" element={<Report />} />
        <Route path="rechargePlans" element={<RechargePlans />} />
        <Route path="billFetch" element={<BillFetch />} />
        <Route path="seeMore" element={<SeeMore />} />
        <Route path="transactionStatus" element={<TransactionStatus />} />
        <Route path="dayBook" element={<DayBook />} />
        <Route path="fundRequest" element={<FundRequest />} />
        <Route path="apiConfiguration" element={<ApiConfiguration />} />
        <Route path="documentation" element={<Documentation />} />
        <Route path="redirectPage" element={<RedirectPage />} />
        <Route path="terms-services" element={<Terms />} />
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
        <Route path="refund-policy" element={<RefundPolicy />} />
        <Route path="account-deletion" element={<AccountDeletion />} />
        <Route path="user" element={<User />} />
      </Route>
    </Routes>
  </BrowserRouter>
);

reportWebVitals();
