import React, { useEffect, useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Loading from "../Components/Loading";
import Error from "../Components/Error";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

const Operator = () => {
  const base_url = process.env.REACT_APP_BASE_URL;
  const key = localStorage.getItem("key");
  const navigate = useNavigate();
  const [originalMargins, setOriginalMargins] = useState([]); // Unfiltered data
  const [margins, setMargins] = useState([]); // Filtered data
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchData, setSearchData] = useState("");

  const [searchParams] = useSearchParams();
  const category = searchParams.get("category");
  const sub_category = searchParams.get("sub_category");

  const handleBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await fetch(`${base_url}app/operator.php?key=${key}&category=${category}&sub_category=${sub_category}`);
        const data = await response.json();
        if (data.status === "Success") {
          setOriginalMargins(data.data1);
          setMargins(data.data1); // Initialize both states
        } else {
          setError("Failed to fetch data.");
        }
      } catch (error) {
        setError("Error fetching data.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [category]);

  const filterOperator = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchData(query);

    // Filter originalMargins based on the search query
    const filteredData = originalMargins.filter(
      (item) =>
        item.biller_name.toLowerCase().includes(query) || // Search by name
        item.biller_category.toLowerCase().includes(query) // Search by category
    );

    setMargins(filteredData);
  };

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return (
      <div className="bg-slate-200 h-screen flex items-center justify-center">
        <Error title="An error occurred" description="Please try refreshing the page or go back. If the problem persists, contact support." />
      </div>
    );
  }

  return (
    <div className="bg-slate-200 min-h-screen select-none pb-[6px]">
      <div className="h-[60px] w-full appBgColor flex items-center px-3 gap-3 text-white sticky top-0">
        <button onClick={handleBack}>
          <FaArrowLeft size={20} />
        </button>
        <h1 className="font-bold select-none">Operator</h1>
      </div>
      <div className="sticky top-[60px] py-2 px-3 bg-slate-200">
        <FontAwesomeIcon icon={faSearch} className="absolute right-7 top-1/2 transform -translate-y-1/2 cursor-pointer" />
        <input type="search" onChange={(e) => filterOperator(e)} value={searchData} placeholder="Search" className="py-[12px] border-2 appBorderColor rounded-lg px-2 w-full outline-none" />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 md:gap-2 lg:gap-3 px-3 md:px-2 lg:px-3 md:pt-2 lg:pt-3 pb-3">
        {margins.map((item) => (
          <Link to={`/operatorForm?operator_id=${item.operator_id}`} key={item.operator_id} className="bg-white mt-[6px] md:mt-0 flex items-center justify-between px-3 py-3 md:py-4 lg:py-5 select-none gap-4 rounded-lg ">
            <div className="flex items-center gap-5 w-full">
              <img src={item.operator_logo} alt={`${item.biller_name} logo`} className="h-[35px] w-[35px]" />
              <div>
                <h1 className="font-bold text-[15px]">{item.biller_name}</h1>
                <h2 className="text-[12px]">{item.biller_category}</h2>
              </div>
            </div>
            <div className="w-[120px]">
              <h2 className="bg-zinc-200 px-2 py-1 rounded text-[12px] flex items-center justify-center">
                {item.commission} {item.commission_type === "%" ? "%" : "Flat"}
              </h2>
            </div>
          </Link>
        ))}
      </div>
      <ToastContainer />
    </div>
  );
};

export default Operator;
