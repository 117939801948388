import React, { useState, useEffect, useRef } from "react";
import Header from "../Components/Header";
import Loading from "../Components/Loading";
import Error from "../Components/Error";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import { MdEdit } from "react-icons/md";

const Profile = () => {
  const base_url = process.env.REACT_APP_BASE_URL;
  const key = localStorage.getItem("key");
  const [showOptions, setShowOptions] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [updateStatus, setUpdateStatus] = useState("");
  const [showAnimation, setShowAnimation] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const modalRef = useRef(null);
  const [profileData, setProfileData] = useState({
    name: "",
    email: "",
    address: "",
  });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (showOptions) {
      setShowAnimation(true);
    } else {
      setShowAnimation(false);
    }
  }, [showOptions]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setShowOptions(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleFileUpload = (type) => {
    if (type === "selfie") {
      document.getElementById("fileInputSelfie").click();
    } else if (type === "gallery") {
      document.getElementById("fileInput").click();
    }
    setShowOptions(false);
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    async function fetchProfileData() {
      try {
        const response = await fetch(`${base_url}app/get_profile.php?key=${key}`);
        const data = await response.json();

        if (data.status === "Success") {
          setProfileData({
            name: data.data1 || "",
            email: data.data2 || "",
            address: data.data3 || "",
          });
        } else {
          console.error("Failed to fetch profile data:", data.message);
          setError(true);
        }
      } catch (error) {
        console.error("Error fetching profile data:", error);
        setError(true);
      } finally {
        setLoading(false);
      }
    }

    fetchProfileData();
  }, []);

  const handleUpdateProfile = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await fetch(`${base_url}app/save_profile.php?key=${key}&name=${profileData.name}&email=${profileData.email}&address=${profileData.address}`);
      const data = await response.json();

      if (data.status === "Success") {
        toast.success("Profile updated successfully!", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
        });
      } else {
        toast.error(data.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
        });
      }
    } catch (error) {
      toast.error("An error occurred. Please try again.", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
      });
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Error title="An error occurred" description="Please try refreshing the page or go back. If the problem persists, contact support." />;
  }

  return (
    <div className="bg-slate-200 h-screen">
      <ToastContainer />
      <div className="h-[60px] w-full appBgColor flex items-center px-3 gap-3 text-white">
        <Link to="/home">
          <FaArrowLeft size={20} />
        </Link>
        <h1 className="font-bold">Edit Profile</h1>
      </div>
      <div className="flex flex-col items-center justify-center py-1 appBgColor pb-2">
        <div className="h-[100px] w-[100px] bg-blue-500 rounded-full relative flex items-center justify-center">
          {selectedImage ? <img src={selectedImage} alt="Profile" className="h-full w-full object-cover rounded-full bg-white" /> : <h1 className="text-[30px] text-white font-bold">DP</h1>}
          {/* <div className="h-[35px] w-[35px] bg-gray-200 flex items-center justify-center rounded-full overflow-hidden absolute bottom-0 right-0 cursor-pointer" onClick={() => setShowOptions(true)}>
            <MdEdit size={18} className="text-gray-600" />
          </div> */}
        </div>
        <h1 className="text-[22px] font-bold select-none text-white py-2">Update your profile</h1>

        <input id="fileInput" type="file" accept="image/*" className="hidden" onChange={handleImageChange} />
        <input id="fileInputSelfie" type="file" accept="image/*" className="hidden" capture="environment" onChange={handleImageChange} />

        {showOptions && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div ref={modalRef} className={`bg-white rounded-lg p-4 w-[320px] transform transition-all duration-500 ease-out ${showAnimation ? "opacity-100 translate-y-0" : "opacity-0"}`}>
              <h2 className="text-[18px] font-bold mb-4">Choose an Option</h2>
              <button className="w-full py-2 rounded-md text-start" onClick={() => handleFileUpload("selfie")}>
                Take Selfie
              </button>
              <button className="w-full py-2 rounded-md text-start" onClick={() => handleFileUpload("gallery")}>
                Choose from Gallery
              </button>
            </div>
          </div>
        )}
      </div>
      {updateStatus && <p className="text-center text-lg font-semibold">{updateStatus}</p>}
      <form className="p-2 px-3 space-y-4 pt-6" onSubmit={handleUpdateProfile}>
        <input type="text" value={profileData.name} placeholder="Update your name" className="w-full py-3 px-2 outline-none border-2 appBorderColor rounded-lg" onChange={(e) => setProfileData({ ...profileData, name: e.target.value })} />
        <input type="email" value={profileData.email} placeholder="Update your email" className="w-full py-3 px-2 outline-none border-2 appBorderColor rounded-lg" onChange={(e) => setProfileData({ ...profileData, email: e.target.value })} />
        <textarea type="text" value={profileData.address} placeholder="Update your address" className="w-full py-3 px-2 outline-none border-2 appBorderColor rounded-lg" onChange={(e) => setProfileData({ ...profileData, address: e.target.value })} />
        <button className="appBgColor text-white py-[12px] w-full font-bold rounded-lg select-none" type="submit">
          SAVE
        </button>
      </form>
    </div>
  );
};

export default Profile;
