import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { FaArrowLeft } from "react-icons/fa";
import AutoDeleteOutlinedIcon from "@mui/icons-material/AutoDeleteOutlined";
import PersonPinIcon from "@mui/icons-material/PersonPin";
import PrivacyTipIcon from "@mui/icons-material/PrivacyTip";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import MailIcon from "@mui/icons-material/Mail";
import StarPurple500OutlinedIcon from "@mui/icons-material/StarPurple500Outlined";
import HeadsetMicIcon from "@mui/icons-material/HeadsetMic";
import LogoutIcon from "@mui/icons-material/Logout";
import FeedIcon from "@mui/icons-material/Feed";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import FavoriteIcon from "@mui/icons-material/Favorite";
const Setting = () => {
  const base_url = process.env.REACT_APP_BASE_URL;
  const key = localStorage.getItem("key");
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleBack = () => {
    navigate(-1);
  };
  const handleLogout = () => {
    localStorage.clear();
    sessionStorage.clear();
    document.cookie = "session=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    window.location.href = "/";
  };
  return (
    <div className="bg-slate-200 select-none pb-5 min-h-screen">
      <div className="h-[60px] w-full appBgColor flex items-center px-3 gap-3 text-white sticky top-0 z-50">
        <button onClick={handleBack}>
          <FaArrowLeft size={20} />
        </button>
        <h1 className="font-bold">Settings</h1>
      </div>
      <div className="px-3">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 md:gap-2 lg:gap-3 md:px-2 lg:px-3 md:mt-2 lg:mt-3">
          <Link to="/profile" className="flex items-center gap-4 bg-white mt-2 md:mt-0 py-4 md:py-6 px-3 rounded-lg md:rounded">
            <PersonPinIcon style={{ fontSize: "35px", color: "gray" }} />
            <div className="opacity-80">
              <h1 className="text-[12px] font-bold leading-[1.5]">My Profile</h1>
              <p className="text-[11px] font-semibold leading-[1.4]">Easily manage and update your personal information for a more tailored experience on our platform.</p>
            </div>
          </Link>
          <Link to="/privacy-policy" className="flex items-center gap-4 bg-white mt-1 md:mt-0 py-4 md:py-6 px-3 rounded-lg md:rounded">
            <PrivacyTipIcon style={{ fontSize: "35px", color: "gray" }} />
            <div className="opacity-80">
              <h1 className="text-[12px] font-bold leading-[1.5]">Privacy Policy</h1>
              <p className="text-[11px] font-semibold leading-[1.4]">Learn more about our stringent privacy measures and how we protect your personal information.</p>
            </div>
          </Link>
          <Link to="/terms-services" className="flex items-center gap-4 bg-white mt-1 md:mt-0 py-4 md:py-6 px-3 rounded-lg md:rounded">
            <FeedIcon style={{ fontSize: "35px", color: "gray" }} />
            <div className="opacity-80">
              <h1 className="text-[12px] font-bold leading-[1.5]">Terms & Service</h1>
              <p className="text-[11px] font-semibold leading-[1.4]">Review our terms and services to understand the guidelines and agreement of our services.</p>
            </div>
          </Link>
          <Link to="/refund-policy" className="flex items-center gap-4 bg-white mt-1 md:mt-0 py-4 md:py-6 px-3 rounded-lg md:rounded">
            <AccountBalanceWalletIcon style={{ fontSize: "35px", color: "gray" }} />
            <div className="opacity-80">
              <h1 className="text-[12px] font-bold leading-[1.5]">Refund policy</h1>
              <p className="text-[11px] font-semibold leading-[1.4]">Please read our refund policy to understand the conditions and procedures for eligibility for a refund.</p>
            </div>
          </Link>
          <a href="mailto:everythinglearn89@gmail.com" className="flex items-center gap-4 bg-white mt-1 md:mt-0 py-4 md:py-6 px-3 rounded-lg md:rounded">
            <MailIcon style={{ fontSize: "35px", color: "gray" }} />
            <div className="opacity-80">
              <h1 className="text-[12px] font-bold leading-[1.5]">Write to us</h1>
              <p className="text-[11px] font-semibold leading-[1.4]">Feel free to reach out to us with any inquiries, feedback or assistance you may need: we're here to help.</p>
            </div>
          </a>
          <a href="https://play.google.com/store/apps/details?id=recharge.save.official" target="_blank" rel="noopener noreferrer">
            <div className="flex items-center gap-4 bg-white mt-1 md:mt-0 py-4 md:py-6 px-3 rounded-lg md:rounded cursor-pointer">
              <StarPurple500OutlinedIcon style={{ fontSize: "35px", color: "gray" }} />
              <div className="opacity-80">
                <h1 className="text-[12px] font-bold leading-[1.5]">Rate our service</h1>
                <p className="text-[11px] font-semibold leading-[1.4]">Share your valuable feedback and experiences with our service to help us improve and serve you better.</p>
              </div>
            </div>
          </a>
          <a href="https://wa.me/917382056372" target="_blank" rel="noopener noreferrer">
            <div className="flex items-center gap-4 bg-white mt-1 md:mt-0 py-4 md:py-6 px-3 rounded-lg md:rounded cursor-pointer">
              <HeadsetMicIcon style={{ fontSize: "35px", color: "gray" }} />
              <div className="opacity-80">
                <h1 className="text-[12px] font-bold leading-[1.5]">Contact us</h1>
                <p className="text-[11px] font-semibold leading-[1.4]">Access our dedicated support team for prompt assistance with any questions, concerns or technical issues.</p>
              </div>
            </div>
          </a>
          <Link to="/account-deletion" className="flex items-center gap-4 bg-white mt-1 md:mt-0 py-4 md:py-6 px-3 rounded-lg md:rounded">
            <AutoDeleteOutlinedIcon style={{ fontSize: "35px", color: "gray" }} />
            <div className="opacity-80">
              <h1 className="text-[12px] font-bold leading-[1.5]">Account Deletion</h1>
              <p className="text-[11px] font-semibold leading-[1.4]"> Please read our account deletion policy to understand the conditions and procedures for permanently deleting your account.</p>
            </div>
          </Link>
          <button className="flex items-center gap-4 bg-white mt-1 md:mt-0 py-4 md:py-6 px-3 rounded-lg md:rounded w-full" onClick={() => setShowModal(true)}>
            <LogoutIcon style={{ fontSize: "35px", color: "gray" }} />
            <div className="opacity-80">
              <h1 className="text-[12px] font-bold leading-[1.5] text-start">Logout</h1>
              <p className="text-[11px] font-semibold leading-[1.4] text-start">Logout securely to protect your account and ensure the confidentiality of your personal information.</p>
            </div>
          </button>
        </div>
      </div>
      {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <motion.div className="bg-white w-80 rounded-lg shadow-lg p-4" initial={{ scale: 0.5 }} animate={{ scale: 1 }} transition={{ duration: 0.3, ease: "easeOut" }}>
            <div className="flex items-center gap-1">
              <WarningRoundedIcon style={{ fontSize: "30px", color: "gray" }} />
              <h2 className="text-lg font-bold">Logout</h2>
            </div>
            <p className="mt-4 text-[16px] text-gray-700">Do you really want to logout?</p>
            <div className="flex justify-end mt-6">
              <button className="px-3  md:rounded py-2 appTextColor mr-2" onClick={() => setShowModal(false)}>
                Cancel
              </button>
              <button className="px-3  md:rounded py-2 appTextColor" onClick={handleLogout}>
                OK
              </button>
            </div>
          </motion.div>
        </div>
      )}
      <div className="flex flex-col items-center justify-center py-3 opacity-60 lg:mt-[50px]">
        <h1 className="text-[30px] font-bold">Recharge Save</h1>
        <h2 className="text-[15px] font-semibold">Mobile Recharge App</h2>
        <div className="flex gap-1 items-center">
          <h3 className="text-[15px] font-semibold">Made with</h3>
          <FavoriteIcon style={{ fontSize: "20px", color: "red" }} />
          <h3 className="text-[15px] font-semibold">in India</h3>
        </div>
      </div>
    </div>
  );
};

export default Setting;
