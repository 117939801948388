import React from "react";
import { useNavigate } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";

const AccountDeletion = () => {
  const navigate = useNavigate();
  const handleBack = () => {
    navigate(-1);
  };
  return (
    <div>
      <div className="h-[60px] w-full bg-[#681667] flex items-center px-2 gap-3 text-white sticky top-0">
        <button onClick={handleBack}>
          <FaArrowLeft size={20} />
        </button>
        <h1 className="font-bold select-none">Account Deletion</h1>
      </div>
      <div className="page-container">
        <div className="bg-white" style={{ padding: "10px", margin: "5px", borderRadius: "5px", justifyContent: "center", alignItems: "center", alignContent: "center" }}>
          <b style={{ textAlign: "center", fontSize: "14px" }}>Dear Recharge Save User,</b>
          <p>
            If you wish to delete your account and associated data, Please mail on our official mail id [
            <a href="mailto:Recharge Save.help@gmail.com?subject=Data%20Deletion%20Request" className="text-blue-700 underline">
              recharge.saveindia@gmail.com
            </a>
            ].
          </p>
          <p style={{ marginTop: "15px" }}>Please include the following details:</p>
          <ul style={{ padding: "5px 10px" }}>
            <li>Registered mobile number</li>
            <li>Last recharge amount (for verification)</li>
            <li>ID proof (Optional)</li>
          </ul>
          <b>Note: </b>Mails are accepted only from the registered email ID provided during registration.
        </div>
        <div className="bg-white" style={{ padding: "10px", margin: "5px", borderRadius: "5px", justifyContent: "center", alignItems: "center", alignContent: "center" }}>
          <b style={{ textAlign: "center", fontSize: "14px" }}>Date Delelion Information</b>
          <ul style={{ padding: "5px 10px" }}>
            <li>User profile Information</li>
            <li>Transaction History</li>
          </ul>
        </div>
        <div className="bg-white" style={{ padding: "10px", margin: "5px", borderRadius: "5px", justifyContent: "center", alignItems: "center", alignContent: "center" }}>
          <b style={{ textAlign: "center", fontSize: "14px" }}>Retention Period</b>
          <p style={{ padding: "5px 0px" }}>
            We will retain certain data for a period of <b>30 days</b> after account deletion for audit and legal purposes.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AccountDeletion;
