import React, { useEffect } from "react";
import ReportProblemRoundedIcon from "@mui/icons-material/ReportProblemRounded";
import { Link, useNavigate } from "react-router-dom";
function PendingActivity({ message, order_id }) {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);
  const handleReceipt = () => {
    navigate(`/transactionStatus?order_id=${order_id}`);
  };
  return (
    <div className="h-screen w-full bg-yellow-600 flex flex-col items-center justify-center px-4">
      <div className="border-b-2 w-full pb-2">
        <div className="text-center ">
          <ReportProblemRoundedIcon style={{ fontSize: "64px", color: "white" }} />
          <h1 className="mt-4 text-white text-xl font-bold">Transaction pending</h1>
        </div>
      </div>
      <div className="pt-5 flex items-center gap-4">
        <button className="bg-blue-600 text-white px-5 py-2 rounded select-none" onClick={handleReceipt}>
          VIEW RECEIPT
        </button>
        <Link to="/home" className="bg-red-600 text-white px-5 py-2 rounded select-none">
          CLOSE
        </Link>
      </div>
    </div>
  );
}

export default PendingActivity;
