import React, { useEffect } from "react";
const LoadingActivity = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    const preventNavigation = (event) => {
      event.preventDefault();
      event.stopPropagation();
      window.history.pushState(null, "", window.location.href);
    };
    window.history.pushState(null, "", window.location.href);
    window.addEventListener("popstate", preventNavigation);

    return () => {
      window.removeEventListener("popstate", preventNavigation);
    };
  }, []);
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <svg className="animate-spin h-[60px] w-[60px] text-indigo-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle className="opacity-100" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
        <path className="opacity-50" color="white" fill="currentColor" d="M4 12a8 8 0 018-8V0C3.82 0 0 5.82 0 12h4z"></path>
      </svg>
    </div>
  );
};

export default LoadingActivity;
