import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faCheckCircle, faTimesCircle, faHourglassHalf, faEye } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import Loading from "../Components/Loading";
import Error from "../Components/Error";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaArrowLeft } from "react-icons/fa";
import _ from "lodash";

const Report = () => {
  const base_url = process.env.REACT_APP_BASE_URL;
  const key = localStorage.getItem("key");
  const [report, setReport] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [searchInput, setSearchInput] = useState(""); // Search term
  const [limit] = useState(20); // Fixed limit for pagination
  const [offset, setOffset] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Fetch data from API
  const fetchData = async (reset = false, searchValue = "") => {
    if (reset) {
      setOffset(0);
      setHasMore(true);
      setReport([]);
    }

    if (!hasMore && !reset) return;

    setLoading(true);
    setError(false);
    try {
      const response = await fetch(`${base_url}app/report.php?key=${key}&number=${searchValue}&limit=${limit}&offset=${reset ? 0 : offset}`);
      const data = await response.json();
      if (data.status === "Success") {
        setReport((prev) => (reset ? data.data1 : [...prev, ...data.data1]));
        setOffset((prev) => prev + limit);
        if (data.data1.length < limit) {
          setHasMore(false);
        }
      } else {
        toast.error(data.message || "No more records found");
        setHasMore(false);
      }
    } catch (error) {
      setError(true);
      toast.error("API fetching error");
    } finally {
      setLoading(false);
    }
  };

  // Call API when the page loads
  useEffect(() => {
    fetchData(true); // Fetch initial data on page load
  }, []);

  // Handle search form submission
  const handleSearchSubmit = (e) => {
    e.preventDefault();
    fetchData(true, searchInput.trim()); // Pass the current search value directly
  };

  // Handle back navigation
  const handleBack = () => {
    navigate(-1);
  };

  // Infinite scroll listener
  const handleScroll = _.throttle(() => {
    if (window.innerHeight + document.documentElement.scrollTop >= document.documentElement.offsetHeight - 100) {
      fetchData(false, searchInput.trim());
    }
  }, 200);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [offset, hasMore]);

  return (
    <>
      {loading && offset === 0 ? (
        <Loading />
      ) : error ? (
        <Error title={"Something went wrong"} description={"API fetching error"}>
          <button onClick={() => fetchData(true, searchInput.trim())} className="bg-blue-500 text-white py-2 px-4 rounded-lg mt-2">
            Retry
          </button>
        </Error>
      ) : (
        <div className="pb-2 bg-slate-200 min-h-screen">
          <div className="sticky top-0 mt-0">
            <div className="h-[60px] w-full appBgColor flex items-center px-3 gap-3 text-white sticky top-0 z-50">
              <button onClick={handleBack} aria-label="Go Back">
                <FaArrowLeft size={20} />
              </button>
              <h1 className="font-bold">Report</h1>
            </div>
            <form className="relative py-2 bg-slate-200 px-3" onSubmit={handleSearchSubmit}>
              <FontAwesomeIcon icon={faSearch} className="absolute right-7 top-1/2 transform -translate-y-1/2 cursor-pointer" aria-hidden="true" />
              <input type="number" value={searchInput} onChange={(e) => setSearchInput(e.target.value)} placeholder="Enter recharge number" className="py-[12px] border-2 appBorderColor rounded-lg px-2 w-full outline-none" aria-label="Search Reports" />
            </form>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 px-3 lg:px-4 pt-2 lg:pt-4 gap-1 lg:gap-3">
            {report.map((item) => (
              <div key={item.transactionId} className="bg-white rounded-lg shadow-md px-3 py-2 mb-1 border border-gray-200">
                <div className="flex items-center justify-between mb-">
                  <div className="text-gray-700 text-[14px] font-bold">
                    {item.category} | {item.number}
                  </div>
                  <img src={item.operator_logo} alt="Operator Logo" height={25} width={25} />
                </div>
                <div className="text-[12px] text-gray-600">Amount: {item.amount}</div>
                <div className="text-[12px] text-gray-600">Time: {item.createAt}</div>
                <div className="text-[12px] text-gray-600">Tnx Id: {item.transaction_id}</div>
                <div className="flex space-x-4 pt-1">
                  {item.status === "Success" ? (
                    <div className="flex items-center justify-center w-1/2 bg-green-500 text-white font-semibold py-[4px] rounded-full gap-2 select-none">
                      <FontAwesomeIcon icon={faCheckCircle} className="text-white" size="xl" />
                      <p>Success</p>
                    </div>
                  ) : item.status === "Failed" ? (
                    <div className="flex items-center justify-center w-1/2 bg-red-500 text-white font-semibold py-[4px] rounded-full gap-2 select-none">
                      <FontAwesomeIcon icon={faTimesCircle} className="text-white" size="xl" />
                      <p>Failed</p>
                    </div>
                  ) : (
                    <div className="flex items-center justify-center w-1/2 bg-yellow-500 text-white font-semibold py-[4px] rounded-full gap-2 select-none">
                      <FontAwesomeIcon icon={faHourglassHalf} className="text-white" size="lg" />
                      <p>Pending</p>
                    </div>
                  )}
                  <Link to={`/transactionStatus?order_id=${item.order_id}`} className="flex items-center justify-center w-1/2 bg-cyan-600 text-white font-semibold py-[5px] rounded-full hover:bg-blue-600 transition-all duration-150 gap-2 select-none">
                    <FontAwesomeIcon icon={faEye} className="text-white" style={{ fontSize: 22 }} />
                    <p>View</p>
                  </Link>
                </div>
              </div>
            ))}
          </div>

          {loading && offset > 0 && <Loading />}
          {!hasMore && !loading && <div className="text-center text-gray-500 mt-4">No more records</div>}

          <ToastContainer />
        </div>
      )}
    </>
  );
};

export default Report;
