import React from "react";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import { Splide, SplideSlide } from "@splidejs/react-splide";
function Splided() {
  return (
    <div className="carousel">
      <div className="splide">
        <Splide
          options={{
            pagination: true,
            arrows: false,
            drag: true,
            autoplay: false,
            interval: 3000,
            speed: 1000,
          }}
        >
          <SplideSlide>
            <a href="https://api.whatsapp.com/send?phone=918403957036" target="_blank" rel="noopener noreferrer">
              <img src="/main.png" alt="carousel" className=" h-full md:h-[300px] lg:h-[400px] w-full rounded" />
            </a>
          </SplideSlide>
          <SplideSlide>
            <a href="https://api.whatsapp.com/send?phone=918403957036" target="_blank" rel="noopener noreferrer">
              <img src="/main.png" alt="carousel" className=" h-full md:h-[300px] lg:h-[400px] w-full rounded" />
            </a>
          </SplideSlide>
        </Splide>
      </div>
    </div>
  );
}

export default Splided;
